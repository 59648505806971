import React from 'react';
import { BrandCarousel } from './BrandCarousel';
import { brandData } from '../../data/brandData';

function transformBrandData() {
  return brandData.map(brand => {
    // Calculate rating based on review count
    const rating = 4.0 + (Math.min(brand.review_count || 0, 10000) / 10000) * 0.9;

    // Extract specialty from the name or summary
    const specialtyMatch = brand.name.match(/(?:with|,)\s([^,]+?)(?:Recipe|Dog Food|$)/i);
    const specialty = specialtyMatch ? 
                     specialtyMatch[1].trim() : 
                     'Complete Nutrition';

    // Extract features from the summary
    const features = brand.gemini_summary
      ? brand.gemini_summary
          .split(/[.,]/)
          .filter(s => s.trim().length > 10)
          .slice(0, 3)
          .map(s => s.trim())
      : ['Quality ingredients', 'Complete nutrition', 'Balanced formula'];

    // Determine price range based on brand name and features
    const priceRange = brand.name.toLowerCase().includes('premium') || 
                      brand.gemini_summary?.toLowerCase().includes('premium') ? 
                      'Premium' : 'Moderate';

    return {
      name: brand.name,
      rating: Number(rating.toFixed(1)),
      priceRange,
      specialty,
      image: brand.image_url,
      features,
      link: brand.link
    };
  });
}

export function BrandGrid() {
  const brands = transformBrandData();
  console.log('Total brands:', brands.length); // Debug log to verify brand count

  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Featured Brands</h2>
          <p className="mt-4 text-lg text-gray-600">Top-rated dog food brands that meet our quality standards</p>
        </div>

        <div className="relative">
          <BrandCarousel brands={brands} />
        </div>
      </div>
    </div>
  );
}