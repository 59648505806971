import React from 'react';
import { BackToBlog } from '../components/blog/BackToBlog';
import { SeniorDogsHeader } from '../components/blog/senior-dogs/SeniorDogsHeader';
import { NutritionalNeeds } from '../components/blog/senior-dogs/NutritionalNeeds';
import { RecommendedIngredients } from '../components/blog/senior-dogs/RecommendedIngredients';
import { CommonIssues } from '../components/blog/senior-dogs/CommonIssues';
import { FeedingTips } from '../components/blog/senior-dogs/FeedingTips';
import { BrandRecommendations } from '../components/blog/senior-dogs/BrandRecommendations';
import { BlogPostLayout } from '../components/layouts/BlogPostLayout';

export function SeniorDogsBlog() {
  return (
    <BlogPostLayout>
      <div>
        <BackToBlog />
        <SeniorDogsHeader />
      </div>
      <NutritionalNeeds />
      <RecommendedIngredients />
      <CommonIssues />
      <FeedingTips />
      <BrandRecommendations />
    </BlogPostLayout>
  );
}