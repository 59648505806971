import React from 'react';
import { BackToBlog } from '../components/blog/BackToBlog';
import { GrainFreeHeader } from '../components/blog/grain-free/GrainFreeHeader';
import { WhatIsGrainFree } from '../components/blog/grain-free/WhatIsGrainFree';
import { BenefitsRisks } from '../components/blog/grain-free/BenefitsRisks';
import { ResearchFindings } from '../components/blog/grain-free/ResearchFindings';
import { AlternativeOptions } from '../components/blog/grain-free/AlternativeOptions';
import { ExpertRecommendations } from '../components/blog/grain-free/ExpertRecommendations';
import { BlogPostLayout } from '../components/layouts/BlogPostLayout';

export function GrainFreeBlog() {
  return (
    <BlogPostLayout>
      <div>
        <BackToBlog />
        <GrainFreeHeader />
      </div>
      <WhatIsGrainFree />
      <BenefitsRisks />
      <ResearchFindings />
      <AlternativeOptions />
      <ExpertRecommendations />
    </BlogPostLayout>
  );
}