import React from 'react';
import { AlertCircle } from 'lucide-react';

export function CommonMisconceptions() {
  const misconceptions = [
    {
      myth: "First ingredient = main ingredient",
      reality: "Ingredients are listed by weight before cooking. After cooking, the order may change significantly."
    },
    {
      myth: "By-products are always bad",
      reality: "Some by-products can be nutritious. The key is knowing the source and quality."
    },
    {
      myth: "Higher protein is always better",
      reality: "Optimal protein levels depend on your dog's age, health, and activity level."
    },
    {
      myth: "Natural means healthier",
      reality: "'Natural' has no standard definition in pet food. Focus on nutritional adequacy instead."
    }
  ];

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Common Misconceptions</h2>
      
      <div className="space-y-6">
        {misconceptions.map((item) => (
          <div key={item.myth} className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-start">
              <AlertCircle className="h-6 w-6 text-amber-500 mt-1 mr-3" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Myth: {item.myth}</h3>
                <p className="text-gray-600">
                  <span className="font-medium text-emerald-600">Reality: </span>
                  {item.reality}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}