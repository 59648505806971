import React from 'react';
import { Check, X } from 'lucide-react';

export function IngredientGuide() {
  const ingredients = {
    good: [
      {
        name: "Named Meat Sources",
        examples: "Chicken, Beef, Lamb",
        why: "Clear protein source identification"
      },
      {
        name: "Whole Grains",
        examples: "Brown Rice, Oats",
        why: "Complex carbohydrates and fiber"
      },
      {
        name: "Named Meat Meals",
        examples: "Chicken Meal, Turkey Meal",
        why: "Concentrated protein source"
      }
    ],
    avoid: [
      {
        name: "Generic By-products",
        examples: "Meat By-products, Animal Digest",
        why: "Unknown source and quality"
      },
      {
        name: "Artificial Preservatives",
        examples: "BHA, BHT, Ethoxyquin",
        why: "Potential health concerns"
      },
      {
        name: "Artificial Colors",
        examples: "Red 40, Blue 2, Yellow 5",
        why: "Unnecessary additives"
      }
    ]
  };

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Ingredient Guide</h2>
      
      <div className="grid gap-8 md:grid-cols-2">
        <div>
          <h3 className="text-xl font-semibold text-green-600 mb-6 flex items-center">
            <Check className="h-6 w-6 mr-2" />
            Preferred Ingredients
          </h3>
          <div className="space-y-4">
            {ingredients.good.map((item) => (
              <div key={item.name} className="bg-white p-4 rounded-lg shadow-md">
                <h4 className="font-medium text-gray-900 mb-2">{item.name}</h4>
                <p className="text-sm text-gray-600 mb-2">
                  <span className="font-medium">Examples: </span>
                  {item.examples}
                </p>
                <p className="text-sm text-gray-600">
                  <span className="font-medium">Why: </span>
                  {item.why}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h3 className="text-xl font-semibold text-red-600 mb-6 flex items-center">
            <X className="h-6 w-6 mr-2" />
            Ingredients to Avoid
          </h3>
          <div className="space-y-4">
            {ingredients.avoid.map((item) => (
              <div key={item.name} className="bg-white p-4 rounded-lg shadow-md">
                <h4 className="font-medium text-gray-900 mb-2">{item.name}</h4>
                <p className="text-sm text-gray-600 mb-2">
                  <span className="font-medium">Examples: </span>
                  {item.examples}
                </p>
                <p className="text-sm text-gray-600">
                  <span className="font-medium">Why: </span>
                  {item.why}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}