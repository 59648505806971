import { SearchResult } from '../types/search';
import { blogPosts } from './blogPosts';
import { foodTypes } from './foodTypes';
import { brands } from './brands';
import { specialNeeds } from './specialNeeds';
import { lifeStages } from './lifeStages';

// Combine all content sources
export const searchableContent: SearchResult[] = [
  ...blogPosts.map(post => ({
    id: post.id,
    type: 'article' as const,
    title: post.title,
    description: post.excerpt,
    content: post.content, // Full article content
    tags: post.tags,
    url: post.page,
    image: post.image,
    category: post.category
  })),
  
  ...foodTypes.map(type => ({
    id: type.id,
    type: 'food' as const,
    title: type.name,
    description: type.description,
    content: `${type.description} ${type.pros.join(' ')} ${type.cons.join(' ')}`,
    tags: [...type.pros, ...type.cons],
    url: 'food-types',
    category: 'Food Types'
  })),
  
  ...brands.map(brand => ({
    id: brand.id,
    type: 'brand' as const,
    title: brand.name,
    description: brand.description,
    content: `${brand.description} ${brand.features.join(' ')}`,
    tags: brand.features,
    url: 'brands',
    category: 'Brands'
  })),
  
  ...specialNeeds.map(need => ({
    id: need.id,
    type: 'special-need' as const,
    title: need.title,
    description: need.description,
    content: `${need.description} ${need.recommendations.join(' ')}`,
    tags: need.tags,
    url: 'special-needs',
    category: 'Special Needs'
  })),
  
  ...lifeStages.map(stage => ({
    id: stage.id,
    type: 'life-stage' as const,
    title: stage.title,
    description: stage.description,
    content: `${stage.description} ${stage.keyPoints.join(' ')}`,
    tags: stage.tags,
    url: 'life-stages',
    category: 'Life Stages'
  }))
];