import React from 'react';
import { ContentLayout } from './ContentLayout';

interface BlogPostLayoutProps {
  children: React.ReactNode;
}

export function BlogPostLayout({ children }: BlogPostLayoutProps) {
  const childrenArray = React.Children.toArray(children);
  
  return (
    <ContentLayout>
      {/* Header section */}
      <div className="mb-8">
        {childrenArray[0]}
      </div>
      
      {/* Main content */}
      <div className="prose prose-emerald max-w-none">
        {childrenArray.slice(1)}
      </div>
    </ContentLayout>
  );
}