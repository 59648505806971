import { SearchResult } from '../types/search';

const STOP_WORDS = new Set(['the', 'and', 'for', 'with', 'dog', 'food']);

function normalizeText(text: string | undefined): string {
  if (!text) return '';
  return text.toLowerCase().trim();
}

function tokenize(text: string): string[] {
  return text
    .toLowerCase()
    .split(/[\s.,!?-]+/)
    .filter(token => token.length > 2 && !STOP_WORDS.has(token));
}

function calculateScore(term: string, item: SearchResult): number {
  let score = 0;
  const normalizedTerm = normalizeText(term);
  
  // Title scoring (highest weight)
  if (normalizeText(item.title).includes(normalizedTerm)) {
    score += 100;
    // Bonus for exact word match in title
    if (tokenize(item.title).includes(normalizedTerm)) {
      score += 50;
    }
  }

  // Description scoring
  if (normalizeText(item.description).includes(normalizedTerm)) {
    score += 50;
  }

  // Content scoring
  if (normalizeText(item.content).includes(normalizedTerm)) {
    score += 25;
    // Bonus for frequency
    const contentTokens = tokenize(item.content || '');
    const frequency = contentTokens.filter(token => token === normalizedTerm).length;
    score += Math.min(frequency * 5, 25); // Cap frequency bonus
  }

  // Tag scoring
  if (item.tags?.some(tag => normalizeText(tag).includes(normalizedTerm))) {
    score += 75;
  }

  // Brand-specific scoring
  if (item.type === 'brand') {
    const brandName = tokenize(item.title)[0];
    if (brandName === normalizedTerm) {
      score += 200; // Exact brand match
    } else if (brandName.startsWith(normalizedTerm)) {
      score += 150; // Brand prefix match
    }
  }

  return score;
}

export function searchContent(query: string, content: SearchResult[]): SearchResult[] {
  if (!query.trim()) return [];

  const searchTerms = query
    .toLowerCase()
    .split(/\s+/)
    .filter(term => term.length >= 2 && !STOP_WORDS.has(term));

  if (searchTerms.length === 0) return [];

  return content
    .map(item => ({
      ...item,
      score: searchTerms.reduce((acc, term) => acc + calculateScore(term, item), 0)
    }))
    .filter(item => item.score > 0)
    .sort((a, b) => b.score - a.score)
    .map(({ score, ...item }) => item)
    .slice(0, 10); // Limit results to top 10
}