import React from 'react';
import { Star, Shield, DollarSign } from 'lucide-react';

export function BrandRecommendations() {
  const brands = [
    {
      name: "Senior Vitality Plus",
      features: [
        "Joint support formula",
        "Enhanced digestibility",
        "Cognitive health blend"
      ],
      price: "Premium",
      rating: 4.8
    },
    {
      name: "Golden Years Nutrition",
      features: [
        "Balanced protein levels",
        "Omega fatty acids",
        "Antioxidant rich"
      ],
      price: "Moderate",
      rating: 4.7
    },
    {
      name: "Senior Care Select",
      features: [
        "Easy-to-chew kibble",
        "Probiotic support",
        "Weight management"
      ],
      price: "Premium",
      rating: 4.6
    }
  ];

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Recommended Brands</h2>
      
      <div className="grid gap-8 md:grid-cols-3">
        {brands.map((brand) => (
          <div key={brand.name} className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="p-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{brand.name}</h3>
              
              <div className="space-y-4">
                <div className="flex items-center">
                  <Star className="h-5 w-5 text-yellow-400 mr-2" />
                  <span className="text-gray-600">{brand.rating} / 5.0</span>
                </div>
                
                <div className="flex items-center">
                  <DollarSign className="h-5 w-5 text-emerald-600 mr-2" />
                  <span className="text-gray-600">{brand.price}</span>
                </div>

                <div>
                  <h4 className="font-medium text-gray-900 mb-2">Key Features:</h4>
                  <ul className="space-y-2">
                    {brand.features.map((feature) => (
                      <li key={feature} className="flex items-center text-gray-600">
                        <Shield className="h-4 w-4 text-emerald-500 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}