import React from 'react';
import { useNavigation } from '../contexts/NavigationContext';
import { AffiliateDisclosure } from './common/AffiliateDisclosure';

export function Footer() {
  const { navigate } = useNavigation();

  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">About</h3>
            <p className="mt-4 text-base text-gray-500">
              PawfectChow helps dog owners make informed decisions about their pets' nutrition 
              through research-backed recommendations and comprehensive reviews.
            </p>
          </div>
          
          <div>
            <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">Legal</h3>
            <ul className="mt-4 space-y-4">
              <li>
                <button 
                  onClick={() => navigate('privacy-policy')}
                  className="text-base text-gray-500 hover:text-gray-900"
                >
                  Privacy Policy
                </button>
              </li>
              <li>
                <button
                  onClick={() => navigate('terms-of-service')}
                  className="text-base text-gray-500 hover:text-gray-900"
                >
                  Terms of Service
                </button>
              </li>
              <li>
                <AffiliateDisclosure variant="inline" />
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">Contact</h3>
            <p className="mt-4 text-base text-gray-500">
              Questions or suggestions? We'd love to hear from you.
            </p>
            <a 
              href="mailto:contact@pawfectchow.com" 
              className="mt-2 inline-block text-emerald-600 hover:text-emerald-500"
            >
              contact@pawfectchow.com
            </a>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t border-gray-200">
          <AffiliateDisclosure />
        </div>
        
        <div className="mt-8 text-base text-gray-400 text-center">
          <p>&copy; {new Date().getFullYear()} PawfectChow. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}