import React from 'react';
import { Hero } from '../components/Hero';
import { FeaturedCategories } from '../components/FeaturedCategories';
import { RecentArticles } from '../components/RecentArticles';

export function Home() {
  return (
    <>
      <Hero />
      <FeaturedCategories />
      <RecentArticles />
    </>
  );
}