import React from 'react';
import { BrandsHero } from '../components/brands/BrandsHero';
import { BrandGrid } from '../components/brands/BrandGrid';
import { ComparisonTable } from '../components/brands/ComparisonTable';
import { QualityStandards } from '../components/brands/QualityStandards';
import { BrandSelection } from '../components/brands/BrandSelection';
import { CategoryLayout } from '../components/layouts/CategoryLayout';

export function Brands() {
  return (
    <CategoryLayout fullWidth>
      <BrandsHero />
      <BrandGrid />
      <ComparisonTable />
      <QualityStandards />
      <BrandSelection />
    </CategoryLayout>
  );
}