import React, { useEffect } from 'react';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { NavigationProvider } from './contexts/NavigationContext';
import { Home } from './pages/Home';
import { FoodTypes } from './pages/FoodTypes';
import { LifeStages } from './pages/LifeStages';
import { SpecialNeeds } from './pages/SpecialNeeds';
import { Brands } from './pages/Brands';
import { BlogPost } from './pages/BlogPost';
import { FoodLabelsBlog } from './pages/FoodLabelsBlog';
import { GrainFreeBlog } from './pages/GrainFreeBlog';
import { SeniorDogsBlog } from './pages/SeniorDogsBlog';
import { TheCompleteGuideToBalancedDogNutritionWhatBlog } from './pages/TheCompleteGuideToBalancedDogNutritionWhatBlog';
import { UnderstandingCatNutritionEssentialNutrientsFoBlog } from './pages/UnderstandingCatNutritionEssentialNutrientsFoBlog';
import { ProteinSourcesInPetFoodAComprehensiveComparBlog } from './pages/ProteinSourcesInPetFoodAComprehensiveComparBlog';
import { CarbohydratesInPetFoodFriendOrFoeBlog } from './pages/CarbohydratesInPetFoodFriendOrFoeBlog';
import { EssentialFattyAcidsWhyYourPetNeedsOmega3ABlog } from './pages/EssentialFattyAcidsWhyYourPetNeedsOmega3ABlog';
import { HowProperNutritionCanExtendYourPetSLifespaBlog } from './pages/HowProperNutritionCanExtendYourPetSLifespaBlog';
import { TheConnectionBetweenDietAndYourPetSCoatHeBlog } from './pages/TheConnectionBetweenDietAndYourPetSCoatHeBlog';
import { NutritionForJointHealthFoodsThatFightArthriBlog } from './pages/NutritionForJointHealthFoodsThatFightArthriBlog';
import { DigestiveHealthChoosingTheRightFoodForSensiBlog } from './pages/DigestiveHealthChoosingTheRightFoodForSensiBlog';
import { TheImpactOfDietOnYourPetSDentalHealthBlog } from './pages/TheImpactOfDietOnYourPetSDentalHealthBlog';
import { GrainFreeDietsBenefitsAndConsiderationsForYBlog } from './pages/GrainFreeDietsBenefitsAndConsiderationsForYBlog';
import { RawFoodDietsAComprehensiveGuideForDogOwnerBlog } from './pages/RawFoodDietsAComprehensiveGuideForDogOwnerBlog';
import { HypoallergenicPetFoodsSolutionsForAllergicPeBlog } from './pages/HypoallergenicPetFoodsSolutionsForAllergicPeBlog';
import { LowCarbDietsForDiabeticPetsWhatYouNeedToBlog } from './pages/LowCarbDietsForDiabeticPetsWhatYouNeedToBlog';
import { WeightManagementNutritionPlansForOverweightPBlog } from './pages/WeightManagementNutritionPlansForOverweightPBlog';
import { NutritionalNeedsOfLargeBreedDogsFromPuppyhoBlog } from './pages/NutritionalNeedsOfLargeBreedDogsFromPuppyhoBlog';
import { SmallBreedNutritionMeetingTheUniqueNeedsOfBlog } from './pages/SmallBreedNutritionMeetingTheUniqueNeedsOfBlog';
import { BrachycephalicBreedsSpecialDietaryConsideratioBlog } from './pages/BrachycephalicBreedsSpecialDietaryConsideratioBlog';
import { NutritionForHighEnergyWorkingDogsFuelingPerBlog } from './pages/NutritionForHighEnergyWorkingDogsFuelingPerBlog';
import { BreedSpecificHealthIssuesAndHowDietCanHelpBlog } from './pages/BreedSpecificHealthIssuesAndHowDietCanHelpBlog';
import { PuppyNutritionBuildingBlocksForAHealthyLifeBlog } from './pages/PuppyNutritionBuildingBlocksForAHealthyLifeBlog';
import { SeniorPetNutritionAdaptingDietAsYourPetAgeBlog } from './pages/SeniorPetNutritionAdaptingDietAsYourPetAgeBlog';
import { NutritionDuringPregnancySupportingMotherDogsBlog } from './pages/NutritionDuringPregnancySupportingMotherDogsBlog';
import { TransitioningFromPuppyToAdultFoodWhenAndHoBlog } from './pages/TransitioningFromPuppyToAdultFoodWhenAndHoBlog';
import { NutritionForGeriatricPetsSupportingQualityOfBlog } from './pages/NutritionForGeriatricPetsSupportingQualityOfBlog';
import { FishOilBenefitsForDogsImprovingSkinCoatAndBlog } from './pages/FishOilBenefitsForDogsImprovingSkinCoatAndBlog';
import { ProbioticsForPetsEnhancingGutHealthAndImmunBlog } from './pages/ProbioticsForPetsEnhancingGutHealthAndImmunBlog';
import { JointSupplementsGlucosamineChondroitinAndMsmBlog } from './pages/JointSupplementsGlucosamineChondroitinAndMsmBlog';
import { VitaminSupplementsWhenAreTheyNecessaryForYoBlog } from './pages/VitaminSupplementsWhenAreTheyNecessaryForYoBlog';
import { NaturalSupplementsForPetAnxietyAndStressRelBlog } from './pages/NaturalSupplementsForPetAnxietyAndStressRelBlog';
import { DecodingPetFoodLabelsWhatThoseIngredientsReBlog } from './pages/DecodingPetFoodLabelsWhatThoseIngredientsReBlog';
import { SuperfoodsForPetsNutrientDenseAdditionsToYoBlog } from './pages/SuperfoodsForPetsNutrientDenseAdditionsToYoBlog';
import { ControversialIngredientsInPetFoodSeparatingFBlog } from './pages/ControversialIngredientsInPetFoodSeparatingFBlog';
import { NovelProteinsBenefitsOfExoticMeatSourcesInBlog } from './pages/NovelProteinsBenefitsOfExoticMeatSourcesInBlog';
import { PreservativesInPetFoodTheGoodTheBadAndTheBlog } from './pages/PreservativesInPetFoodTheGoodTheBadAndTheBlog';
import { SummerNutritionKeepingYourPetHydratedAndHeaBlog } from './pages/SummerNutritionKeepingYourPetHydratedAndHeaBlog';
import { WinterDietAdjustmentsShouldYouFeedMoreInCoBlog } from './pages/WinterDietAdjustmentsShouldYouFeedMoreInCoBlog';
import { SeasonalAllergiesHowDietCanHelpManageSymptoBlog } from './pages/SeasonalAllergiesHowDietCanHelpManageSymptoBlog';
import { HolidayFoodSafetyProtectingYourPetDuringFesBlog } from './pages/HolidayFoodSafetyProtectingYourPetDuringFesBlog';
import { SeasonalIngredientsIncorporatingFreshSeasonalBlog } from './pages/SeasonalIngredientsIncorporatingFreshSeasonalBlog';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfService';
import { updateMetaTags } from './utils/seo';
import { initializeRouter, PageName } from './utils/router';
import { cleanupAdSlots, initializeGlobalAdState } from './utils/ads/state';

export default function App() {
  const [currentPage, setCurrentPage] = React.useState<PageName>('home');

  useEffect(() => {
    initializeRouter(setCurrentPage);
  }, []);

  useEffect(() => {
    updateMetaTags(currentPage);
    cleanupAdSlots();
    initializeGlobalAdState();
  }, [currentPage]);

  return (
    <NavigationProvider onNavigate={setCurrentPage}>
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <Header />
        <main className="flex-grow">
          {currentPage === 'home' && <Home />}
          {currentPage === 'food-types' && <FoodTypes />}
          {currentPage === 'life-stages' && <LifeStages />}
          {currentPage === 'special-needs' && <SpecialNeeds />}
          {currentPage === 'brands' && <Brands />}
          {currentPage === 'blog' && <BlogPost />}
          {currentPage === 'blog-food-labels' && <FoodLabelsBlog />}
          {currentPage === 'blog-grain-free' && <GrainFreeBlog />}
          {currentPage === 'blog-senior-dogs' && <SeniorDogsBlog />}
          {currentPage === 'blog-the-complete-guide-to-balanced-dog-nutrition-what' && <TheCompleteGuideToBalancedDogNutritionWhatBlog />}
          {currentPage === 'blog-understanding-cat-nutrition-essential-nutrients-fo' && <UnderstandingCatNutritionEssentialNutrientsFoBlog />}
          {currentPage === 'blog-protein-sources-in-pet-food-a-comprehensive-compar' && <ProteinSourcesInPetFoodAComprehensiveComparBlog />}
          {currentPage === 'blog-carbohydrates-in-pet-food-friend-or-foe' && <CarbohydratesInPetFoodFriendOrFoeBlog />}
          {currentPage === 'blog-essential-fatty-acids-why-your-pet-needs-omega-3-a' && <EssentialFattyAcidsWhyYourPetNeedsOmega3ABlog />}
          {currentPage === 'blog-how-proper-nutrition-can-extend-your-pet-s-lifespa' && <HowProperNutritionCanExtendYourPetSLifespaBlog />}
          {currentPage === 'blog-the-connection-between-diet-and-your-pet-s-coat-he' && <TheConnectionBetweenDietAndYourPetSCoatHeBlog />}
          {currentPage === 'blog-nutrition-for-joint-health-foods-that-fight-arthri' && <NutritionForJointHealthFoodsThatFightArthriBlog />}
          {currentPage === 'blog-digestive-health-choosing-the-right-food-for-sensi' && <DigestiveHealthChoosingTheRightFoodForSensiBlog />}
          {currentPage === 'blog-the-impact-of-diet-on-your-pet-s-dental-health' && <TheImpactOfDietOnYourPetSDentalHealthBlog />}
          {currentPage === 'blog-grain-free-diets-benefits-and-considerations-for-y' && <GrainFreeDietsBenefitsAndConsiderationsForYBlog />}
          {currentPage === 'blog-raw-food-diets-a-comprehensive-guide-for-dog-owner' && <RawFoodDietsAComprehensiveGuideForDogOwnerBlog />}
          {currentPage === 'blog-hypoallergenic-pet-foods-solutions-for-allergic-pe' && <HypoallergenicPetFoodsSolutionsForAllergicPeBlog />}
          {currentPage === 'blog-low-carb-diets-for-diabetic-pets-what-you-need-to' && <LowCarbDietsForDiabeticPetsWhatYouNeedToBlog />}
          {currentPage === 'blog-weight-management-nutrition-plans-for-overweight-p' && <WeightManagementNutritionPlansForOverweightPBlog />}
          {currentPage === 'blog-nutritional-needs-of-large-breed-dogs-from-puppyho' && <NutritionalNeedsOfLargeBreedDogsFromPuppyhoBlog />}
          {currentPage === 'blog-small-breed-nutrition-meeting-the-unique-needs-of' && <SmallBreedNutritionMeetingTheUniqueNeedsOfBlog />}
          {currentPage === 'blog-brachycephalic-breeds-special-dietary-consideratio' && <BrachycephalicBreedsSpecialDietaryConsideratioBlog />}
          {currentPage === 'blog-nutrition-for-high-energy-working-dogs-fueling-per' && <NutritionForHighEnergyWorkingDogsFuelingPerBlog />}
          {currentPage === 'blog-breed-specific-health-issues-and-how-diet-can-help' && <BreedSpecificHealthIssuesAndHowDietCanHelpBlog />}
          {currentPage === 'blog-puppy-nutrition-building-blocks-for-a-healthy-life' && <PuppyNutritionBuildingBlocksForAHealthyLifeBlog />}
          {currentPage === 'blog-senior-pet-nutrition-adapting-diet-as-your-pet-age' && <SeniorPetNutritionAdaptingDietAsYourPetAgeBlog />}
          {currentPage === 'blog-nutrition-during-pregnancy-supporting-mother-dogs' && <NutritionDuringPregnancySupportingMotherDogsBlog />}
          {currentPage === 'blog-transitioning-from-puppy-to-adult-food-when-and-ho' && <TransitioningFromPuppyToAdultFoodWhenAndHoBlog />}
          {currentPage === 'blog-nutrition-for-geriatric-pets-supporting-quality-of' && <NutritionForGeriatricPetsSupportingQualityOfBlog />}
          {currentPage === 'blog-fish-oil-benefits-for-dogs-improving-skin-coat-and' && <FishOilBenefitsForDogsImprovingSkinCoatAndBlog />}
          {currentPage === 'blog-probiotics-for-pets-enhancing-gut-health-and-immun' && <ProbioticsForPetsEnhancingGutHealthAndImmunBlog />}
          {currentPage === 'blog-joint-supplements-glucosamine-chondroitin-and-msm' && <JointSupplementsGlucosamineChondroitinAndMsmBlog />}
          {currentPage === 'blog-vitamin-supplements-when-are-they-necessary-for-yo' && <VitaminSupplementsWhenAreTheyNecessaryForYoBlog />}
          {currentPage === 'blog-natural-supplements-for-pet-anxiety-and-stress-rel' && <NaturalSupplementsForPetAnxietyAndStressRelBlog />}
          {currentPage === 'blog-decoding-pet-food-labels-what-those-ingredients-re' && <DecodingPetFoodLabelsWhatThoseIngredientsReBlog />}
          {currentPage === 'blog-superfoods-for-pets-nutrient-dense-additions-to-yo' && <SuperfoodsForPetsNutrientDenseAdditionsToYoBlog />}
          {currentPage === 'blog-controversial-ingredients-in-pet-food-separating-f' && <ControversialIngredientsInPetFoodSeparatingFBlog />}
          {currentPage === 'blog-novel-proteins-benefits-of-exotic-meat-sources-in' && <NovelProteinsBenefitsOfExoticMeatSourcesInBlog />}
          {currentPage === 'blog-preservatives-in-pet-food-the-good-the-bad-and-the' && <PreservativesInPetFoodTheGoodTheBadAndTheBlog />}
          {currentPage === 'blog-summer-nutrition-keeping-your-pet-hydrated-and-hea' && <SummerNutritionKeepingYourPetHydratedAndHeaBlog />}
          {currentPage === 'blog-winter-diet-adjustments-should-you-feed-more-in-co' && <WinterDietAdjustmentsShouldYouFeedMoreInCoBlog />}
          {currentPage === 'blog-seasonal-allergies-how-diet-can-help-manage-sympto' && <SeasonalAllergiesHowDietCanHelpManageSymptoBlog />}
          {currentPage === 'blog-holiday-food-safety-protecting-your-pet-during-fes' && <HolidayFoodSafetyProtectingYourPetDuringFesBlog />}
          {currentPage === 'blog-seasonal-ingredients-incorporating-fresh-seasonal' && <SeasonalIngredientsIncorporatingFreshSeasonalBlog />}
          {currentPage === 'privacy-policy' && <PrivacyPolicy />}
          {currentPage === 'terms-of-service' && <TermsOfService />}
        </main>
        <Footer />
      </div>
    </NavigationProvider>
  );
}