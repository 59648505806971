import { ROUTES } from './router';

interface PageMetadata {
  title: string;
  description: string;
  path: string;
  schema: object;
}

const PAGE_METADATA: Record<string, PageMetadata> = {
  home: {
    title: 'PawfectChow - Dog Food Guide & Reviews',
    description: 'Expert dog food recommendations, nutrition guides, and brand reviews to help you make the best choice for your furry friend.',
    path: '',
    schema: {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "PawfectChow",
      "url": "https://pawfectchow.com",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://pawfectchow.com/search?q={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    }
  },
  'food-types': {
    title: 'Dog Food Types Guide | PawfectChow',
    description: 'Compare different types of dog food including dry kibble, wet food, raw diet, and more. Find the best option for your pet.',
    path: 'food-types',
    schema: {
      "@context": "https://schema.org",
      "@type": "Article",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/food-types"
      },
      "headline": "Complete Guide to Dog Food Types",
      "description": "Compare different types of dog food including dry kibble, wet food, raw diet, and more.",
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-the-complete-guide-to-balanced-dog-nutrition-what': {
    title: 'The Complete Guide to Balanced Dog Nutrition: What Every Owner Should Know | PawfectChow',
    description: 'Learn about essential nutrients, proper portions, and how to choose the right dog food for optimal health. Expert advice on balanced nutrition for dogs of all ages.',
    path: 'blog-the-complete-guide-to-balanced-dog-nutrition-what',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-the-complete-guide-to-balanced-dog-nutrition-what"
      },
      "headline": "The Complete Guide to Balanced Dog Nutrition: What Every Owner Should Know",
      "description": "Learn about essential nutrients, proper portions, and how to choose the right dog food for optimal health. Expert advice on balanced nutrition for dogs of all ages.",
      "datePublished": "January 30, 2025",
      "author": {
        "@type": "Person",
        "name": "the Pawfectchow team"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-understanding-cat-nutrition-essential-nutrients-fo': {
    title: 'Understanding Cat Nutrition: Essential Nutrients for Feline Health | PawfectChow',
    description: 'Discover the key nutrients cats need for optimal health. Learn about protein requirements, essential fatty acids, vitamins, and minerals that keep your feline friend healthy and happy.',
    path: 'blog-understanding-cat-nutrition-essential-nutrients-fo',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-understanding-cat-nutrition-essential-nutrients-fo"
      },
      "headline": "Understanding Cat Nutrition: Essential Nutrients for Feline Health",
      "description": "Discover the key nutrients cats need for optimal health. Learn about protein requirements, essential fatty acids, vitamins, and minerals that keep your feline friend healthy and happy.",
      "datePublished": "January 31, 2025",
      "author": {
        "@type": "Person",
        "name": "Mark Johnson, Pet Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-protein-sources-in-pet-food-a-comprehensive-compar': {
    title: 'Protein Sources in Pet Food: A Comprehensive Comparison | PawfectChow',
    description: 'Compare different protein sources in pet food including chicken, beef, fish, lamb, and novel proteins. Learn which options are best for allergies, digestive health, and muscle development.',
    path: 'blog-protein-sources-in-pet-food-a-comprehensive-compar',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-protein-sources-in-pet-food-a-comprehensive-compar"
      },
      "headline": "Protein Sources in Pet Food: A Comprehensive Comparison",
      "description": "Compare different protein sources in pet food including chicken, beef, fish, lamb, and novel proteins. Learn which options are best for allergies, digestive health, and muscle development.",
      "datePublished": "February 1, 2025",
      "author": {
        "@type": "Person",
        "name": "Emma Roberts, Canine Specialist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-carbohydrates-in-pet-food-friend-or-foe': {
    title: 'Carbohydrates in Pet Food: Friend or Foe? | PawfectChow',
    description: 'Published by Dr. James Chen, Veterinary Nutritionist',
    path: 'blog-carbohydrates-in-pet-food-friend-or-foe',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-carbohydrates-in-pet-food-friend-or-foe"
      },
      "headline": "Carbohydrates in Pet Food: Friend or Foe?",
      "description": "Published by Dr. James Chen, Veterinary Nutritionist",
      "datePublished": "February 2, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. James Chen, Veterinary Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-essential-fatty-acids-why-your-pet-needs-omega-3-a': {
    title: 'Essential Fatty Acids: Why Your Pet Needs Omega-3 and Omega-6 | PawfectChow',
    description: 'Published by Dr. Sarah Wilson, DVM',
    path: 'blog-essential-fatty-acids-why-your-pet-needs-omega-3-a',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-essential-fatty-acids-why-your-pet-needs-omega-3-a"
      },
      "headline": "Essential Fatty Acids: Why Your Pet Needs Omega-3 and Omega-6",
      "description": "Published by Dr. Sarah Wilson, DVM",
      "datePublished": "February 3, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. Sarah Wilson, DVM"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-how-proper-nutrition-can-extend-your-pet-s-lifespa': {
    title: 'How Proper Nutrition Can Extend Your Pet\'s Lifespan | PawfectChow',
    description: 'Published by Mark Johnson, Pet Nutritionist',
    path: 'blog-how-proper-nutrition-can-extend-your-pet-s-lifespa',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-how-proper-nutrition-can-extend-your-pet-s-lifespa"
      },
      "headline": "How Proper Nutrition Can Extend Your Pet's Lifespan",
      "description": "Published by Mark Johnson, Pet Nutritionist",
      "datePublished": "February 4, 2025",
      "author": {
        "@type": "Person",
        "name": "Mark Johnson, Pet Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-the-connection-between-diet-and-your-pet-s-coat-he': {
    title: 'The Connection Between Diet and Your Pet\'s Coat Health | PawfectChow',
    description: 'Published by Emma Roberts, Canine Specialist',
    path: 'blog-the-connection-between-diet-and-your-pet-s-coat-he',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-the-connection-between-diet-and-your-pet-s-coat-he"
      },
      "headline": "The Connection Between Diet and Your Pet's Coat Health",
      "description": "Published by Emma Roberts, Canine Specialist",
      "datePublished": "February 5, 2025",
      "author": {
        "@type": "Person",
        "name": "Emma Roberts, Canine Specialist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-nutrition-for-joint-health-foods-that-fight-arthri': {
    title: 'Nutrition for Joint Health: Foods That Fight Arthritis in Pets | PawfectChow',
    description: 'Published by Dr. James Chen, Veterinary Nutritionist',
    path: 'blog-nutrition-for-joint-health-foods-that-fight-arthri',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-nutrition-for-joint-health-foods-that-fight-arthri"
      },
      "headline": "Nutrition for Joint Health: Foods That Fight Arthritis in Pets",
      "description": "Published by Dr. James Chen, Veterinary Nutritionist",
      "datePublished": "February 6, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. James Chen, Veterinary Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-digestive-health-choosing-the-right-food-for-sensi': {
    title: 'Digestive Health: Choosing the Right Food for Sensitive Stomachs | PawfectChow',
    description: 'Published by Dr. Sarah Wilson, DVM',
    path: 'blog-digestive-health-choosing-the-right-food-for-sensi',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-digestive-health-choosing-the-right-food-for-sensi"
      },
      "headline": "Digestive Health: Choosing the Right Food for Sensitive Stomachs",
      "description": "Published by Dr. Sarah Wilson, DVM",
      "datePublished": "February 7, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. Sarah Wilson, DVM"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-the-impact-of-diet-on-your-pet-s-dental-health': {
    title: 'The Impact of Diet on Your Pet\'s Dental Health | PawfectChow',
    description: 'Published by Mark Johnson, Pet Nutritionist',
    path: 'blog-the-impact-of-diet-on-your-pet-s-dental-health',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-the-impact-of-diet-on-your-pet-s-dental-health"
      },
      "headline": "The Impact of Diet on Your Pet's Dental Health",
      "description": "Published by Mark Johnson, Pet Nutritionist",
      "datePublished": "February 8, 2025",
      "author": {
        "@type": "Person",
        "name": "Mark Johnson, Pet Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-grain-free-diets-benefits-and-considerations-for-y': {
    title: 'Grain-Free Diets: Benefits and Considerations for Your Pet | PawfectChow',
    description: 'Explore the pros and cons of grain-free pet food. Learn about potential benefits for allergies and digestion, plus important health considerations before switching your pet to a grain-free diet.',
    path: 'blog-grain-free-diets-benefits-and-considerations-for-y',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-grain-free-diets-benefits-and-considerations-for-y"
      },
      "headline": "Grain-Free Diets: Benefits and Considerations for Your Pet",
      "description": "Explore the pros and cons of grain-free pet food. Learn about potential benefits for allergies and digestion, plus important health considerations before switching your pet to a grain-free diet.",
      "datePublished": "February 9, 2025",
      "author": {
        "@type": "Person",
        "name": "Emma Roberts, Canine Specialist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-raw-food-diets-a-comprehensive-guide-for-dog-owner': {
    title: 'Raw Food Diets: A Comprehensive Guide for Dog Owners | PawfectChow',
    description: 'Published by Dr. James Chen, Veterinary Nutritionist',
    path: 'blog-raw-food-diets-a-comprehensive-guide-for-dog-owner',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-raw-food-diets-a-comprehensive-guide-for-dog-owner"
      },
      "headline": "Raw Food Diets: A Comprehensive Guide for Dog Owners",
      "description": "Published by Dr. James Chen, Veterinary Nutritionist",
      "datePublished": "February 10, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. James Chen, Veterinary Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-hypoallergenic-pet-foods-solutions-for-allergic-pe': {
    title: 'Hypoallergenic Pet Foods: Solutions for Allergic Pets | PawfectChow',
    description: 'Published by Dr. Sarah Wilson, DVM',
    path: 'blog-hypoallergenic-pet-foods-solutions-for-allergic-pe',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-hypoallergenic-pet-foods-solutions-for-allergic-pe"
      },
      "headline": "Hypoallergenic Pet Foods: Solutions for Allergic Pets",
      "description": "Published by Dr. Sarah Wilson, DVM",
      "datePublished": "February 11, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. Sarah Wilson, DVM"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-low-carb-diets-for-diabetic-pets-what-you-need-to': {
    title: 'Low-Carb Diets for Diabetic Pets: What You Need to Know | PawfectChow',
    description: 'Published by Mark Johnson, Pet Nutritionist',
    path: 'blog-low-carb-diets-for-diabetic-pets-what-you-need-to',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-low-carb-diets-for-diabetic-pets-what-you-need-to"
      },
      "headline": "Low-Carb Diets for Diabetic Pets: What You Need to Know",
      "description": "Published by Mark Johnson, Pet Nutritionist",
      "datePublished": "February 12, 2025",
      "author": {
        "@type": "Person",
        "name": "Mark Johnson, Pet Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-weight-management-nutrition-plans-for-overweight-p': {
    title: 'Weight Management: Nutrition Plans for Overweight Pets | PawfectChow',
    description: 'Published by Emma Roberts, Canine Specialist',
    path: 'blog-weight-management-nutrition-plans-for-overweight-p',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-weight-management-nutrition-plans-for-overweight-p"
      },
      "headline": "Weight Management: Nutrition Plans for Overweight Pets",
      "description": "Published by Emma Roberts, Canine Specialist",
      "datePublished": "February 13, 2025",
      "author": {
        "@type": "Person",
        "name": "Emma Roberts, Canine Specialist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-nutritional-needs-of-large-breed-dogs-from-puppyho': {
    title: 'Nutritional Needs of Large Breed Dogs: From Puppyhood to Adulthood | PawfectChow',
    description: 'Published by Dr. James Chen, Veterinary Nutritionist',
    path: 'blog-nutritional-needs-of-large-breed-dogs-from-puppyho',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-nutritional-needs-of-large-breed-dogs-from-puppyho"
      },
      "headline": "Nutritional Needs of Large Breed Dogs: From Puppyhood to Adulthood",
      "description": "Published by Dr. James Chen, Veterinary Nutritionist",
      "datePublished": "February 14, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. James Chen, Veterinary Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-small-breed-nutrition-meeting-the-unique-needs-of': {
    title: 'Small Breed Nutrition: Meeting the Unique Needs of Tiny Dogs | PawfectChow',
    description: 'Published by Dr. Sarah Wilson, DVM',
    path: 'blog-small-breed-nutrition-meeting-the-unique-needs-of',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-small-breed-nutrition-meeting-the-unique-needs-of"
      },
      "headline": "Small Breed Nutrition: Meeting the Unique Needs of Tiny Dogs",
      "description": "Published by Dr. Sarah Wilson, DVM",
      "datePublished": "February 15, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. Sarah Wilson, DVM"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-brachycephalic-breeds-special-dietary-consideratio': {
    title: 'Brachycephalic Breeds: Special Dietary Considerations for Flat-Faced Dogs | PawfectChow',
    description: 'Published by Mark Johnson, Pet Nutritionist',
    path: 'blog-brachycephalic-breeds-special-dietary-consideratio',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-brachycephalic-breeds-special-dietary-consideratio"
      },
      "headline": "Brachycephalic Breeds: Special Dietary Considerations for Flat-Faced Dogs",
      "description": "Published by Mark Johnson, Pet Nutritionist",
      "datePublished": "February 16, 2025",
      "author": {
        "@type": "Person",
        "name": "Mark Johnson, Pet Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-nutrition-for-high-energy-working-dogs-fueling-per': {
    title: 'Nutrition for High-Energy Working Dogs: Fueling Performance | PawfectChow',
    description: 'Published by Emma Roberts, Canine Specialist',
    path: 'blog-nutrition-for-high-energy-working-dogs-fueling-per',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-nutrition-for-high-energy-working-dogs-fueling-per"
      },
      "headline": "Nutrition for High-Energy Working Dogs: Fueling Performance",
      "description": "Published by Emma Roberts, Canine Specialist",
      "datePublished": "February 17, 2025",
      "author": {
        "@type": "Person",
        "name": "Emma Roberts, Canine Specialist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-breed-specific-health-issues-and-how-diet-can-help': {
    title: 'Breed-Specific Health Issues and How Diet Can Help | PawfectChow',
    description: 'Published by Dr. James Chen, Veterinary Nutritionist',
    path: 'blog-breed-specific-health-issues-and-how-diet-can-help',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-breed-specific-health-issues-and-how-diet-can-help"
      },
      "headline": "Breed-Specific Health Issues and How Diet Can Help",
      "description": "Published by Dr. James Chen, Veterinary Nutritionist",
      "datePublished": "February 18, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. James Chen, Veterinary Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-puppy-nutrition-building-blocks-for-a-healthy-life': {
    title: 'Puppy Nutrition: Building Blocks for a Healthy Life | PawfectChow',
    description: 'Published by Dr. Sarah Wilson, DVM',
    path: 'blog-puppy-nutrition-building-blocks-for-a-healthy-life',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-puppy-nutrition-building-blocks-for-a-healthy-life"
      },
      "headline": "Puppy Nutrition: Building Blocks for a Healthy Life",
      "description": "Published by Dr. Sarah Wilson, DVM",
      "datePublished": "February 19, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. Sarah Wilson, DVM"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-senior-pet-nutrition-adapting-diet-as-your-pet-age': {
    title: 'Senior Pet Nutrition: Adapting Diet as Your Pet Ages | PawfectChow',
    description: 'Published by Mark Johnson, Pet Nutritionist',
    path: 'blog-senior-pet-nutrition-adapting-diet-as-your-pet-age',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-senior-pet-nutrition-adapting-diet-as-your-pet-age"
      },
      "headline": "Senior Pet Nutrition: Adapting Diet as Your Pet Ages",
      "description": "Published by Mark Johnson, Pet Nutritionist",
      "datePublished": "February 20, 2025",
      "author": {
        "@type": "Person",
        "name": "Mark Johnson, Pet Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-nutrition-during-pregnancy-supporting-mother-dogs': {
    title: 'Nutrition During Pregnancy: Supporting Mother Dogs and Their Puppies | PawfectChow',
    description: 'Published by Emma Roberts, Canine Specialist',
    path: 'blog-nutrition-during-pregnancy-supporting-mother-dogs',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-nutrition-during-pregnancy-supporting-mother-dogs"
      },
      "headline": "Nutrition During Pregnancy: Supporting Mother Dogs and Their Puppies",
      "description": "Published by Emma Roberts, Canine Specialist",
      "datePublished": "February 21, 2025",
      "author": {
        "@type": "Person",
        "name": "Emma Roberts, Canine Specialist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-transitioning-from-puppy-to-adult-food-when-and-ho': {
    title: 'Transitioning from Puppy to Adult Food: When and How to Switch | PawfectChow',
    description: 'Published by Dr. James Chen, Veterinary Nutritionist',
    path: 'blog-transitioning-from-puppy-to-adult-food-when-and-ho',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-transitioning-from-puppy-to-adult-food-when-and-ho"
      },
      "headline": "Transitioning from Puppy to Adult Food: When and How to Switch",
      "description": "Published by Dr. James Chen, Veterinary Nutritionist",
      "datePublished": "February 22, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. James Chen, Veterinary Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-nutrition-for-geriatric-pets-supporting-quality-of': {
    title: 'Nutrition for Geriatric Pets: Supporting Quality of Life in the Golden Years | PawfectChow',
    description: 'Published by Dr. Sarah Wilson, DVM',
    path: 'blog-nutrition-for-geriatric-pets-supporting-quality-of',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-nutrition-for-geriatric-pets-supporting-quality-of"
      },
      "headline": "Nutrition for Geriatric Pets: Supporting Quality of Life in the Golden Years",
      "description": "Published by Dr. Sarah Wilson, DVM",
      "datePublished": "February 23, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. Sarah Wilson, DVM"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-fish-oil-benefits-for-dogs-improving-skin-coat-and': {
    title: 'Fish Oil Benefits for Dogs: Improving Skin, Coat, and Overall Health | PawfectChow',
    description: 'Published by Mark Johnson, Pet Nutritionist',
    path: 'blog-fish-oil-benefits-for-dogs-improving-skin-coat-and',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-fish-oil-benefits-for-dogs-improving-skin-coat-and"
      },
      "headline": "Fish Oil Benefits for Dogs: Improving Skin, Coat, and Overall Health",
      "description": "Published by Mark Johnson, Pet Nutritionist",
      "datePublished": "February 24, 2025",
      "author": {
        "@type": "Person",
        "name": "Mark Johnson, Pet Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-probiotics-for-pets-enhancing-gut-health-and-immun': {
    title: 'Probiotics for Pets: Enhancing Gut Health and Immunity | PawfectChow',
    description: 'Published by Emma Roberts, Canine Specialist',
    path: 'blog-probiotics-for-pets-enhancing-gut-health-and-immun',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-probiotics-for-pets-enhancing-gut-health-and-immun"
      },
      "headline": "Probiotics for Pets: Enhancing Gut Health and Immunity",
      "description": "Published by Emma Roberts, Canine Specialist",
      "datePublished": "February 25, 2025",
      "author": {
        "@type": "Person",
        "name": "Emma Roberts, Canine Specialist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-joint-supplements-glucosamine-chondroitin-and-msm': {
    title: 'Joint Supplements: Glucosamine, Chondroitin, and MSM for Aging Pets | PawfectChow',
    description: 'Published by Dr. James Chen, Veterinary Nutritionist',
    path: 'blog-joint-supplements-glucosamine-chondroitin-and-msm',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-joint-supplements-glucosamine-chondroitin-and-msm"
      },
      "headline": "Joint Supplements: Glucosamine, Chondroitin, and MSM for Aging Pets",
      "description": "Published by Dr. James Chen, Veterinary Nutritionist",
      "datePublished": "February 26, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. James Chen, Veterinary Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-vitamin-supplements-when-are-they-necessary-for-yo': {
    title: 'Vitamin Supplements: When Are They Necessary for Your Pet? | PawfectChow',
    description: 'Published by Dr. Sarah Wilson, DVM',
    path: 'blog-vitamin-supplements-when-are-they-necessary-for-yo',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-vitamin-supplements-when-are-they-necessary-for-yo"
      },
      "headline": "Vitamin Supplements: When Are They Necessary for Your Pet?",
      "description": "Published by Dr. Sarah Wilson, DVM",
      "datePublished": "February 27, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. Sarah Wilson, DVM"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-natural-supplements-for-pet-anxiety-and-stress-rel': {
    title: 'Natural Supplements for Pet Anxiety and Stress Relief | PawfectChow',
    description: 'Published by Mark Johnson, Pet Nutritionist',
    path: 'blog-natural-supplements-for-pet-anxiety-and-stress-rel',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-natural-supplements-for-pet-anxiety-and-stress-rel"
      },
      "headline": "Natural Supplements for Pet Anxiety and Stress Relief",
      "description": "Published by Mark Johnson, Pet Nutritionist",
      "datePublished": "February 28, 2025",
      "author": {
        "@type": "Person",
        "name": "Mark Johnson, Pet Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-decoding-pet-food-labels-what-those-ingredients-re': {
    title: 'Decoding Pet Food Labels: What Those Ingredients Really Mean | PawfectChow',
    description: 'Published by Emma Roberts, Canine Specialist',
    path: 'blog-decoding-pet-food-labels-what-those-ingredients-re',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-decoding-pet-food-labels-what-those-ingredients-re"
      },
      "headline": "Decoding Pet Food Labels: What Those Ingredients Really Mean",
      "description": "Published by Emma Roberts, Canine Specialist",
      "datePublished": "March 1, 2025",
      "author": {
        "@type": "Person",
        "name": "Emma Roberts, Canine Specialist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-superfoods-for-pets-nutrient-dense-additions-to-yo': {
    title: 'Superfoods for Pets: Nutrient-Dense Additions to Your Pet\'s Diet | PawfectChow',
    description: 'Published by Dr. James Chen, Veterinary Nutritionist',
    path: 'blog-superfoods-for-pets-nutrient-dense-additions-to-yo',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-superfoods-for-pets-nutrient-dense-additions-to-yo"
      },
      "headline": "Superfoods for Pets: Nutrient-Dense Additions to Your Pet's Diet",
      "description": "Published by Dr. James Chen, Veterinary Nutritionist",
      "datePublished": "March 2, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. James Chen, Veterinary Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-controversial-ingredients-in-pet-food-separating-f': {
    title: 'Controversial Ingredients in Pet Food: Separating Fact from Fiction | PawfectChow',
    description: 'Published by Dr. Sarah Wilson, DVM',
    path: 'blog-controversial-ingredients-in-pet-food-separating-f',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-controversial-ingredients-in-pet-food-separating-f"
      },
      "headline": "Controversial Ingredients in Pet Food: Separating Fact from Fiction",
      "description": "Published by Dr. Sarah Wilson, DVM",
      "datePublished": "March 3, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. Sarah Wilson, DVM"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-novel-proteins-benefits-of-exotic-meat-sources-in': {
    title: 'Novel Proteins: Benefits of Exotic Meat Sources in Pet Food | PawfectChow',
    description: 'Published by Mark Johnson, Pet Nutritionist',
    path: 'blog-novel-proteins-benefits-of-exotic-meat-sources-in',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-novel-proteins-benefits-of-exotic-meat-sources-in"
      },
      "headline": "Novel Proteins: Benefits of Exotic Meat Sources in Pet Food",
      "description": "Published by Mark Johnson, Pet Nutritionist",
      "datePublished": "March 4, 2025",
      "author": {
        "@type": "Person",
        "name": "Mark Johnson, Pet Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-preservatives-in-pet-food-the-good-the-bad-and-the': {
    title: 'Preservatives in Pet Food: The Good, the Bad, and the Ugly | PawfectChow',
    description: 'Published by Emma Roberts, Canine Specialist',
    path: 'blog-preservatives-in-pet-food-the-good-the-bad-and-the',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-preservatives-in-pet-food-the-good-the-bad-and-the"
      },
      "headline": "Preservatives in Pet Food: The Good, the Bad, and the Ugly",
      "description": "Published by Emma Roberts, Canine Specialist",
      "datePublished": "March 5, 2025",
      "author": {
        "@type": "Person",
        "name": "Emma Roberts, Canine Specialist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-summer-nutrition-keeping-your-pet-hydrated-and-hea': {
    title: 'Summer Nutrition: Keeping Your Pet Hydrated and Healthy in Hot Weather | PawfectChow',
    description: 'Published by Dr. James Chen, Veterinary Nutritionist',
    path: 'blog-summer-nutrition-keeping-your-pet-hydrated-and-hea',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-summer-nutrition-keeping-your-pet-hydrated-and-hea"
      },
      "headline": "Summer Nutrition: Keeping Your Pet Hydrated and Healthy in Hot Weather",
      "description": "Published by Dr. James Chen, Veterinary Nutritionist",
      "datePublished": "March 6, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. James Chen, Veterinary Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-winter-diet-adjustments-should-you-feed-more-in-co': {
    title: 'Winter Diet Adjustments: Should You Feed More in Cold Weather? | PawfectChow',
    description: 'Published by Dr. Sarah Wilson, DVM',
    path: 'blog-winter-diet-adjustments-should-you-feed-more-in-co',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-winter-diet-adjustments-should-you-feed-more-in-co"
      },
      "headline": "Winter Diet Adjustments: Should You Feed More in Cold Weather?",
      "description": "Published by Dr. Sarah Wilson, DVM",
      "datePublished": "March 7, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. Sarah Wilson, DVM"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-seasonal-allergies-how-diet-can-help-manage-sympto': {
    title: 'Seasonal Allergies: How Diet Can Help Manage Symptoms | PawfectChow',
    description: 'Published by Mark Johnson, Pet Nutritionist',
    path: 'blog-seasonal-allergies-how-diet-can-help-manage-sympto',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-seasonal-allergies-how-diet-can-help-manage-sympto"
      },
      "headline": "Seasonal Allergies: How Diet Can Help Manage Symptoms",
      "description": "Published by Mark Johnson, Pet Nutritionist",
      "datePublished": "March 8, 2025",
      "author": {
        "@type": "Person",
        "name": "Mark Johnson, Pet Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-holiday-food-safety-protecting-your-pet-during-fes': {
    title: 'Holiday Food Safety: Protecting Your Pet During Festive Seasons | PawfectChow',
    description: 'Published by Emma Roberts, Canine Specialist',
    path: 'blog-holiday-food-safety-protecting-your-pet-during-fes',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-holiday-food-safety-protecting-your-pet-during-fes"
      },
      "headline": "Holiday Food Safety: Protecting Your Pet During Festive Seasons",
      "description": "Published by Emma Roberts, Canine Specialist",
      "datePublished": "March 9, 2025",
      "author": {
        "@type": "Person",
        "name": "Emma Roberts, Canine Specialist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  },
  'blog-seasonal-ingredients-incorporating-fresh-seasonal': {
    title: 'Seasonal Ingredients: Incorporating Fresh, Seasonal Foods into Your Pet\'s Diet | PawfectChow',
    description: 'Published by Dr. James Chen, Veterinary Nutritionist',
    path: 'blog-seasonal-ingredients-incorporating-fresh-seasonal',
    schema: {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://pawfectchow.com/blog-seasonal-ingredients-incorporating-fresh-seasonal"
      },
      "headline": "Seasonal Ingredients: Incorporating Fresh, Seasonal Foods into Your Pet's Diet",
      "description": "Published by Dr. James Chen, Veterinary Nutritionist",
      "datePublished": "March 10, 2025",
      "author": {
        "@type": "Person",
        "name": "Dr. James Chen, Veterinary Nutritionist"
      },
      "publisher": {
        "@type": "Organization",
        "name": "PawfectChow",
        "url": "https://pawfectchow.com"
      }
    }
  }
};

export function updateMetaTags(page: string) {
  const metadata = PAGE_METADATA[page] || PAGE_METADATA.home;
  const baseUrl = 'https://pawfectchow.com';
  const fullUrl = `${baseUrl}/${metadata.path}`;

  // Update basic meta tags
  updateBasicMetaTags(metadata, fullUrl);
  
  // Update Open Graph tags
  updateOpenGraphTags(metadata, fullUrl);
  
  // Update Twitter tags
  updateTwitterTags(metadata, fullUrl);
  
  // Add JSON-LD Schema
  updateSchema(metadata.schema);
  
  // Add preload hints for critical resources
  addPreloadHints();
}

function updateBasicMetaTags(metadata: PageMetadata, url: string) {
  document.title = metadata.title;
  
  updateMetaTag('description', metadata.description);
  updateMetaTag('robots', 'index, follow');
  updateMetaTag('viewport', 'width=device-width, initial-scale=1');
  
  // Update canonical URL
  let canonical = document.querySelector('link[rel="canonical"]');
  if (!canonical) {
    canonical = document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    document.head.appendChild(canonical);
  }
  canonical.setAttribute('href', url);
}

function updateOpenGraphTags(metadata: PageMetadata, url: string) {
  const ogTags = {
    'og:title': metadata.title,
    'og:description': metadata.description,
    'og:url': url,
    'og:type': 'website',
    'og:site_name': 'PawfectChow'
  };

  Object.entries(ogTags).forEach(([property, content]) => {
    let tag = document.querySelector(`meta[property="${property}"]`);
    if (!tag) {
      tag = document.createElement('meta');
      tag.setAttribute('property', property);
      document.head.appendChild(tag);
    }
    tag.setAttribute('content', content);
  });
}

function updateTwitterTags(metadata: PageMetadata, url: string) {
  const twitterTags = {
    'twitter:card': 'summary_large_image',
    'twitter:title': metadata.title,
    'twitter:description': metadata.description,
    'twitter:url': url
  };

  Object.entries(twitterTags).forEach(([name, content]) => {
    let tag = document.querySelector(`meta[name="${name}"]`);
    if (!tag) {
      tag = document.createElement('meta');
      tag.setAttribute('name', name);
      document.head.appendChild(tag);
    }
    tag.setAttribute('content', content);
  });
}

function updateMetaTag(name: string, content: string) {
  let tag = document.querySelector(`meta[name="${name}"]`);
  if (!tag) {
    tag = document.createElement('meta');
    tag.setAttribute('name', name);
    document.head.appendChild(tag);
  }
  tag.setAttribute('content', content);
}

function updateSchema(schema: object) {
  let scriptTag = document.querySelector('script[type="application/ld+json"]');
  if (!scriptTag) {
    scriptTag = document.createElement('script');
    scriptTag.setAttribute('type', 'application/ld+json');
    document.head.appendChild(scriptTag);
  }
  scriptTag.textContent = JSON.stringify(schema);
}

function addPreloadHints() {
  // Preload critical fonts
  addPreloadLink('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap', 'style');
  
  // Preload critical images (logo, hero images)
  addPreloadLink('/assets/logo.svg', 'image');
}

function addPreloadLink(href: string, as: string) {
  let link = document.querySelector(`link[href="${href}"]`);
  if (!link) {
    link = document.createElement('link');
    link.setAttribute('rel', 'preload');
    link.setAttribute('href', href);
    link.setAttribute('as', as);
    document.head.appendChild(link);
  }
}
