import React from 'react';
import { Clock } from 'lucide-react';

export function TransitionGuide() {
  const steps = [
    {
      title: 'Start Gradually',
      description: 'Mix 25% new food with 75% current food for 2-3 days',
      day: 'Days 1-3'
    },
    {
      title: 'Increase New Food',
      description: 'Mix 50% new food with 50% current food for 2-3 days',
      day: 'Days 4-6'
    },
    {
      title: 'Continue Transition',
      description: 'Mix 75% new food with 25% current food for 2-3 days',
      day: 'Days 7-9'
    },
    {
      title: 'Complete Switch',
      description: '100% new food if pet shows no digestive issues',
      day: 'Day 10+'
    }
  ];

  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Food Transition Guide</h2>
          <p className="mt-4 text-lg text-gray-600">How to safely switch between life stage foods</p>
        </div>

        <div className="relative">
          <div className="absolute top-0 left-1/2 w-0.5 h-full bg-emerald-200 transform -translate-x-1/2"></div>
          <div className="space-y-12">
            {steps.map((step, index) => (
              <div key={step.title} className="relative">
                <div className="flex items-center">
                  <div className="flex-1 text-right mr-8 md:mr-16">
                    <h3 className="text-lg font-semibold text-gray-900">{step.title}</h3>
                    <p className="mt-1 text-gray-600">{step.description}</p>
                  </div>
                  <div className="absolute left-1/2 transform -translate-x-1/2">
                    <div className="w-12 h-12 rounded-full bg-emerald-500 flex items-center justify-center">
                      <Clock className="w-6 h-6 text-white" />
                    </div>
                  </div>
                  <div className="flex-1 ml-8 md:ml-16">
                    <div className="text-lg font-medium text-emerald-600">{step.day}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}