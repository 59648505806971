import React from 'react';
import { Calendar, Clock, User } from 'lucide-react';

export function GrainFreeHeader() {
  return (
    <header className="mb-12">
      <h1 className="text-4xl font-extrabold text-gray-900 mb-4">
        The Truth About Grain-Free Dog Food: Benefits, Risks, and Research
      </h1>
      
      <div className="flex items-center space-x-6 text-gray-600 mb-8">
        <div className="flex items-center">
          <Calendar className="h-5 w-5 mr-2" />
          <span>March 18, 2024</span>
        </div>
        <div className="flex items-center">
          <Clock className="h-5 w-5 mr-2" />
          <span>10 min read</span>
        </div>
        <div className="flex items-center">
          <User className="h-5 w-5 mr-2" />
          <span>Dr. Michael Chen, DVM, PhD</span>
        </div>
      </div>

      <img
        src="https://images.unsplash.com/photo-1548199973-03cce0bbc87b?auto=format&fit=crop&q=80&w=1600"
        alt="Various grain-free dog food options"
        className="w-full h-[400px] object-cover rounded-xl mb-8"
      />

      <p className="text-xl text-gray-600 leading-relaxed">
        Grain-free dog food has become increasingly popular in recent years, but what does the science say? 
        This comprehensive analysis explores the benefits, potential risks, and latest research findings to 
        help you make an informed decision about your dog's nutrition.
      </p>
    </header>
  );
}