import React from 'react';
import { Check } from 'lucide-react';

export function RecommendedIngredients() {
  const ingredients = [
    {
      category: "Proteins",
      items: [
        "Easy-to-digest meats (chicken, turkey)",
        "Fish rich in omega-3s",
        "Egg protein"
      ]
    },
    {
      category: "Joint Support",
      items: [
        "Glucosamine-rich ingredients",
        "Chondroitin sources",
        "Green-lipped mussel"
      ]
    },
    {
      category: "Antioxidants",
      items: [
        "Blueberries",
        "Sweet potatoes",
        "Spinach and other leafy greens"
      ]
    }
  ];

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Beneficial Ingredients</h2>
      
      <div className="grid gap-8 md:grid-cols-3">
        {ingredients.map((group) => (
          <div key={group.category} className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">{group.category}</h3>
            <ul className="space-y-3">
              {group.items.map((item) => (
                <li key={item} className="flex items-center text-gray-600">
                  <Check className="h-5 w-5 text-emerald-500 mr-2" />
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
}