import React from 'react';
import { specialNeeds } from '../../data/specialNeeds';
import { Heart, AlertTriangle, Sandwich, Activity, Brain, Smile, Sun, Pill, Droplets, Shield, Star, PanelTopClose, Stethoscope, Zap } from 'lucide-react';

export function ConditionCards() {
  const getIcon = (id: string) => {
    switch (id) {
      case 'heart-health': return <Heart className="h-8 w-8" />;
      case 'allergies': return <AlertTriangle className="h-8 w-8" />;
      case 'digestive': return <Sandwich className="h-8 w-8" />;
      case 'weight-management': return <Activity className="h-8 w-8" />;
      case 'joint-health': return <Activity className="h-8 w-8" />;
      case 'kidney-disease': return <Droplets className="h-8 w-8" />;
      case 'dental-health': return <Smile className="h-8 w-8" />; // Changed from Tooth to Smile
      case 'skin-coat': return <Sun className="h-8 w-8" />;
      case 'liver-support': return <Pill className="h-8 w-8" />;
      case 'diabetes': return <Activity className="h-8 w-8" />;
      case 'cancer-support': return <Shield className="h-8 w-8" />;
      case 'pancreatitis': return <PanelTopClose className="h-8 w-8" />;
      case 'urinary-health': return <Droplets className="h-8 w-8" />;
      case 'ibd': return <Stethoscope className="h-8 w-8" />;
      case 'thyroid': return <Zap className="h-8 w-8" />;
      default: return <Star className="h-8 w-8" />;
    }
  };

  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Specialized Dietary Solutions</h2>
          <p className="mt-4 text-lg text-gray-600">Comprehensive nutritional support for various health conditions</p>
        </div>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {specialNeeds.map((condition) => (
            <div key={condition.id} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
              <div className="p-6">
                <div className="inline-flex p-3 rounded-xl bg-emerald-50 text-emerald-600 mb-4">
                  {getIcon(condition.id)}
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{condition.title}</h3>
                <p className="text-gray-600 mb-4">{condition.description}</p>
                <div>
                  <h4 className="font-medium text-gray-900 mb-2">Dietary Recommendations:</h4>
                  <ul className="space-y-2">
                    {condition.recommendations.map((rec) => (
                      <li key={rec} className="flex items-center text-gray-600">
                        <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2"></span>
                        {rec}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}