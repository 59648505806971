import React from 'react';
import { CheckCircle, AlertTriangle } from 'lucide-react';

export function BenefitsRisks() {
  const analysis = {
    benefits: [
      {
        title: "Food Sensitivities",
        description: "May help dogs with specific grain allergies or sensitivities"
      },
      {
        title: "Digestibility",
        description: "Some dogs may find certain grain alternatives easier to digest"
      },
      {
        title: "Weight Management",
        description: "Often higher in protein and lower in carbohydrates"
      }
    ],
    risks: [
      {
        title: "DCM Concerns",
        description: "Potential link to dilated cardiomyopathy in some dogs"
      },
      {
        title: "Nutritional Balance",
        description: "May lack important nutrients found in whole grains"
      },
      {
        title: "Cost",
        description: "Generally more expensive than grain-inclusive options"
      }
    ]
  };

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Benefits and Risks</h2>
      
      <div className="grid gap-8 md:grid-cols-2">
        <div>
          <h3 className="text-xl font-semibold text-green-600 mb-6 flex items-center">
            <CheckCircle className="h-6 w-6 mr-2" />
            Potential Benefits
          </h3>
          <div className="space-y-4">
            {analysis.benefits.map((item) => (
              <div key={item.title} className="bg-white p-4 rounded-lg shadow-md">
                <h4 className="font-medium text-gray-900 mb-2">{item.title}</h4>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h3 className="text-xl font-semibold text-amber-600 mb-6 flex items-center">
            <AlertTriangle className="h-6 w-6 mr-2" />
            Potential Risks
          </h3>
          <div className="space-y-4">
            {analysis.risks.map((item) => (
              <div key={item.title} className="bg-white p-4 rounded-lg shadow-md">
                <h4 className="font-medium text-gray-900 mb-2">{item.title}</h4>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}