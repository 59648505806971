import React from 'react';
import { Clock, Scale, Utensils } from 'lucide-react';

export function FeedingTips() {
  const tips = [
    {
      icon: <Clock className="h-6 w-6" />,
      title: "Feeding Schedule",
      tips: [
        "Feed smaller portions more frequently",
        "Maintain consistent feeding times",
        "Monitor water intake"
      ]
    },
    {
      icon: <Scale className="h-6 w-6" />,
      title: "Portion Control",
      tips: [
        "Measure food accurately",
        "Adjust portions based on activity level",
        "Regular weight monitoring"
      ]
    },
    {
      icon: <Utensils className="h-6 w-6" />,
      title: "Feeding Environment",
      tips: [
        "Use elevated food bowls",
        "Non-slip mats under bowls",
        "Quiet, peaceful feeding area"
      ]
    }
  ];

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Feeding Tips</h2>
      
      <div className="grid gap-8 md:grid-cols-3">
        {tips.map((section) => (
          <div key={section.title} className="bg-white p-6 rounded-lg shadow-md">
            <div className="inline-flex p-3 rounded-xl bg-emerald-50 text-emerald-600 mb-4">
              {section.icon}
            </div>
            <h3 className="text-xl font-semibold text-gray-900 mb-4">{section.title}</h3>
            <ul className="space-y-3">
              {section.tips.map((tip) => (
                <li key={tip} className="flex items-start text-gray-600">
                  <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2 mt-2"></span>
                  {tip}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
}