import React from 'react';
import { Apple, Leaf, Droplets } from 'lucide-react';

export function NutritionGuide() {
  const guidelines = [
    {
      icon: <Apple className="h-8 w-8" />,
      title: 'Protein Requirements',
      description: 'Adult dogs need 18-25% protein, while puppies require 22-32% for optimal growth.',
    },
    {
      icon: <Leaf className="h-8 w-8" />,
      title: 'Essential Nutrients',
      description: 'Look for foods with balanced omega fatty acids, vitamins, and minerals.',
    },
    {
      icon: <Droplets className="h-8 w-8" />,
      title: 'Moisture Content',
      description: 'Ensure adequate hydration through food and fresh water availability.',
    },
  ];

  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900">Nutrition Guidelines</h2>
          <p className="mt-4 text-xl text-gray-600">Key factors to consider when choosing your dog's food</p>
        </div>

        <div className="mt-12 grid gap-8 md:grid-cols-3">
          {guidelines.map((item) => (
            <div key={item.title} className="bg-emerald-50 rounded-xl p-8 text-center hover:shadow-lg transition-shadow duration-300">
              <div className="inline-flex p-3 rounded-full bg-emerald-100 text-emerald-600 mb-4">
                {item.icon}
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{item.title}</h3>
              <p className="text-gray-600">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}