import React from 'react';
import { Apple, Battery, Brain } from 'lucide-react';

export function NutritionalNeeds() {
  const needs = [
    {
      icon: <Apple className="h-6 w-6" />,
      title: "Caloric Requirements",
      description: "Lower calorie needs due to decreased activity",
      details: "20-30% fewer calories than adult maintenance"
    },
    {
      icon: <Battery className="h-6 w-6" />,
      title: "Protein Levels",
      description: "Higher quality protein to maintain muscle mass",
      details: "25-30% protein content recommended"
    },
    {
      icon: <Brain className="h-6 w-6" />,
      title: "Cognitive Support",
      description: "Antioxidants and omega fatty acids",
      details: "Enhanced DHA and EPA levels"
    }
  ];

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Changed Nutritional Needs</h2>
      
      <div className="grid gap-8 md:grid-cols-3">
        {needs.map((need) => (
          <div key={need.title} className="bg-white p-6 rounded-lg shadow-md">
            <div className="inline-flex p-3 rounded-xl bg-emerald-50 text-emerald-600 mb-4">
              {need.icon}
            </div>
            <h3 className="text-xl font-semibold text-gray-900 mb-2">{need.title}</h3>
            <p className="text-gray-600 mb-4">{need.description}</p>
            <p className="text-sm text-emerald-600 font-medium">{need.details}</p>
          </div>
        ))}
      </div>
    </section>
  );
}