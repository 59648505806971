import React, { useState } from 'react';
import { Dog, Menu } from 'lucide-react';
import { useNavigation } from '../contexts/NavigationContext';
import { MobileMenu } from './MobileMenu';

export function Header() {
  const { navigate } = useNavigation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center cursor-pointer" onClick={() => navigate('home')}>
            <Dog className="h-8 w-8 text-emerald-600" />
            <span className="ml-2 text-2xl font-bold text-gray-900">PawfectChow</span>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            <nav className="flex space-x-8">
              <button
                onClick={() => navigate('food-types')}
                className="text-gray-700 hover:text-emerald-600 font-medium text-lg relative group"
              >
                Food Types
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-emerald-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200" />
              </button>
              <button
                onClick={() => navigate('life-stages')}
                className="text-gray-700 hover:text-emerald-600 font-medium text-lg relative group"
              >
                Life Stages
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-emerald-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200" />
              </button>
              <button
                onClick={() => navigate('special-needs')}
                className="text-gray-700 hover:text-emerald-600 font-medium text-lg relative group"
              >
                Special Needs
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-emerald-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200" />
              </button>
              <button
                onClick={() => navigate('brands')}
                className="text-gray-700 hover:text-emerald-600 font-medium text-lg relative group"
              >
                Top Brands
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-emerald-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200" />
              </button>
              <button
                onClick={() => navigate('blog')}
                className="text-gray-700 hover:text-emerald-600 font-medium text-lg relative group"
              >
                Blog
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-emerald-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200" />
              </button>
            </nav>
          </div>

          <div className="md:hidden">
            <button 
              onClick={() => setIsMobileMenuOpen(true)}
              className="p-2 text-gray-700 hover:text-emerald-600 hover:bg-emerald-50 rounded-full transition-colors duration-200"
            >
              <Menu className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      <MobileMenu 
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
      />
    </header>
  );
}