import React from 'react';
import { BackToBlog } from '../components/blog/BackToBlog';
import { BlogPostLayout } from '../components/layouts/BlogPostLayout';
import { blogPosts } from '../data/blogPosts';
import { ArticleWithAds } from '../components/layouts/ArticleWithAds';

export function WinterDietAdjustmentsShouldYouFeedMoreInCoBlog() {
  // Find the blog post data
  const blogPost = blogPosts.find(post => post.id === 'winter-diet-adjustments-should-you-feed-more-in-co');
  
  if (!blogPost) {
    return <div>Blog post not found</div>;
  }

  return (
    <BlogPostLayout>
      <div>
        <BackToBlog />
        <div className="mb-8">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-4">{blogPost.title}</h1>
          <div className="flex items-center text-sm text-gray-500 mb-4">
            <span className="mr-4">{blogPost.date}</span>
            <span className="mr-4">•</span>
            <span>{blogPost.readTime} read</span>
          </div>
          <div className="text-sm font-medium text-emerald-600 bg-emerald-50 inline-block px-3 py-1 rounded-full">
            {blogPost.category}
          </div>
          <img
            className="w-full h-64 md:h-96 object-cover rounded-xl mt-6"
            src={blogPost.image}
            alt={blogPost.title}
          />
        </div>
      </div>
      <ArticleWithAds>
        <div 
          className="prose prose-emerald max-w-none"
          dangerouslySetInnerHTML={{ __html: blogPost.content }}
        />
      </ArticleWithAds>
    </BlogPostLayout>
  );
}