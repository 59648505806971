import React from 'react';
import { SearchResult } from '../../types/search';
import { FileText, Package, Award } from 'lucide-react';
import { useNavigation } from '../../contexts/NavigationContext';

interface SearchResultsProps {
  results: SearchResult[];
  onClose?: () => void;
}

export function SearchResults({ results, onClose }: SearchResultsProps) {
  const { navigate } = useNavigation();

  const handleSelect = (url: string) => {
    navigate(url);
    if (onClose) {
      onClose();
    }
  };

  const getIcon = (type: SearchResult['type']) => {
    switch (type) {
      case 'article':
        return <FileText className="h-5 w-5 text-emerald-600" />;
      case 'food':
        return <Package className="h-5 w-5 text-emerald-600" />;
      case 'brand':
        return <Award className="h-5 w-5 text-emerald-600" />;
      default:
        return <FileText className="h-5 w-5 text-emerald-600" />;
    }
  };

  return (
    <div className="absolute z-50 mt-2 w-full bg-white rounded-lg shadow-lg border border-gray-200 max-h-96 overflow-y-auto">
      {results.map((result) => (
        <div
          key={result.id}
          onClick={() => handleSelect(result.url)}
          className="w-full px-4 py-3 flex items-start hover:bg-emerald-50 cursor-pointer transition-colors duration-200 border-b border-gray-100 last:border-0"
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleSelect(result.url);
            }
          }}
        >
          <div className="flex-shrink-0 mt-1 mr-3">
            {getIcon(result.type)}
          </div>
          <div className="text-left flex-grow">
            <h3 className="text-sm font-medium text-gray-900 group-hover:text-emerald-600">
              {result.title}
            </h3>
            {result.description && (
              <p className="mt-1 text-sm text-gray-500 line-clamp-2">
                {result.description}
              </p>
            )}
            <span className="mt-1 text-xs text-emerald-600 inline-block px-2 py-0.5 bg-emerald-50 rounded-full">
              {result.category}
            </span>
          </div>
          <div className="flex-shrink-0 ml-2 text-emerald-600">
            <svg 
              className="w-4 h-4 transform transition-transform group-hover:translate-x-1" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>
      ))}
    </div>
  );
}