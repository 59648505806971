import React from 'react';
import { ListChecks } from 'lucide-react';

export function LabelComponents() {
  const components = [
    {
      title: "Product Name",
      description: "The name must follow specific rules. For example, 'Beef Dog Food' must contain at least 95% beef.",
      example: "'Beef Dog Food' vs 'Dog Food with Beef'"
    },
    {
      title: "Net Weight",
      description: "The total amount of food in the package, displayed in both metric and imperial units.",
      example: "12.5 lb (5.67 kg)"
    },
    {
      title: "Manufacturer Information",
      description: "Name and address of the manufacturer, required for traceability and consumer contact.",
      example: "Manufactured by PawfectChow Inc., 123 Pet Food Lane..."
    },
    {
      title: "Feeding Guidelines",
      description: "Recommended daily portions based on dog weight and life stage.",
      example: "For adult dogs (20-30 lbs): 2-2.5 cups daily"
    }
  ];

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Key Label Components</h2>
      
      <div className="grid gap-8 md:grid-cols-2">
        {components.map((component) => (
          <div key={component.title} className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              <ListChecks className="h-6 w-6 text-emerald-600 mr-3" />
              <h3 className="text-xl font-semibold text-gray-900">{component.title}</h3>
            </div>
            <p className="text-gray-600 mb-4">{component.description}</p>
            <div className="bg-emerald-50 p-3 rounded-md">
              <span className="text-sm font-medium text-emerald-800">Example: </span>
              <span className="text-emerald-700">{component.example}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}