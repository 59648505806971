import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { initializeGA } from './utils/analytics';
import { initializePerformanceMonitoring, deferNonCriticalLoad } from './utils/performance';

// Initialize performance monitoring
initializePerformanceMonitoring();

// Initialize Google Analytics
initializeGA();

// Defer non-critical resources
deferNonCriticalLoad();

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
);