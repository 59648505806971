import React from 'react';
import { Apple, Activity, Scale } from 'lucide-react';

export function NutritionalNeeds() {
  const nutritionData = [
    {
      stage: 'Puppy',
      protein: '28-32%',
      fat: '17-22%',
      calories: '3x adult needs',
      specialNeeds: ['DHA for brain development', 'Extra calcium and phosphorus']
    },
    {
      stage: 'Adult',
      protein: '18-25%',
      fat: '10-15%',
      calories: 'Based on activity',
      specialNeeds: ['Joint support', 'Dental health']
    },
    {
      stage: 'Senior',
      protein: '22-28%',
      fat: '10-12%',
      calories: '20% less than adult',
      specialNeeds: ['Joint support', 'Digestive enzymes']
    }
  ];

  return (
    <div className="py-16 bg-emerald-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Nutritional Requirements</h2>
          <p className="mt-4 text-lg text-gray-600">Key nutrients needed at each life stage</p>
        </div>

        <div className="mt-8 overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white rounded-lg shadow-lg">
              <thead className="bg-emerald-500 text-white">
                <tr>
                  <th className="py-4 px-6 text-left">Life Stage</th>
                  <th className="py-4 px-6 text-center">Protein</th>
                  <th className="py-4 px-6 text-center">Fat</th>
                  <th className="py-4 px-6 text-center">Calories</th>
                  <th className="py-4 px-6 text-left">Special Needs</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {nutritionData.map((data) => (
                  <tr key={data.stage} className="hover:bg-gray-50">
                    <td className="py-4 px-6 font-medium text-gray-900">{data.stage}</td>
                    <td className="py-4 px-6 text-center">{data.protein}</td>
                    <td className="py-4 px-6 text-center">{data.fat}</td>
                    <td className="py-4 px-6 text-center">{data.calories}</td>
                    <td className="py-4 px-6">
                      <ul className="list-disc list-inside">
                        {data.specialNeeds.map((need) => (
                          <li key={need} className="text-gray-600">{need}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}