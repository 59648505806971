import React, { createContext, useContext, useCallback } from 'react';
import { pageview } from '../utils/analytics';
import { navigateTo, getRoutePathFromPage, type PageName } from '../utils/router';

interface NavigationContextType {
  navigate: (page: PageName) => void;
}

const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

interface NavigationProviderProps {
  children: React.ReactNode;
  onNavigate: (page: PageName) => void;
}

export function NavigationProvider({ children, onNavigate }: NavigationProviderProps) {
  const navigate = useCallback((page: PageName) => {
    // Get the route path for this page
    const path = getRoutePathFromPage(page);
    
    // Scroll to top
    window.scrollTo(0, 0);
    
    // Update route and track pageview
    navigateTo(path, onNavigate);
    pageview(path);
  }, [onNavigate]);

  return (
    <NavigationContext.Provider value={{ navigate }}>
      {children}
    </NavigationContext.Provider>
  );
}

export function useNavigation() {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
}