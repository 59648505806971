import React from 'react';
import { Calendar, Clock, User } from 'lucide-react';

export function SeniorDogsHeader() {
  return (
    <header className="mb-12">
      <h1 className="text-4xl font-extrabold text-gray-900 mb-4">
        Best Foods for Senior Dogs: A Comprehensive Guide
      </h1>
      
      <div className="flex items-center space-x-6 text-gray-600 mb-8">
        <div className="flex items-center">
          <Calendar className="h-5 w-5 mr-2" />
          <span>March 20, 2024</span>
        </div>
        <div className="flex items-center">
          <Clock className="h-5 w-5 mr-2" />
          <span>12 min read</span>
        </div>
        <div className="flex items-center">
          <User className="h-5 w-5 mr-2" />
          <span>Dr. Emily Wilson, DVM</span>
        </div>
      </div>

      <img
        src="https://images.unsplash.com/photo-1518717758536-85ae29035b6d?auto=format&fit=crop&q=80&w=1600"
        alt="Senior dog being fed"
        className="w-full h-[400px] object-cover rounded-xl mb-8"
      />

      <p className="text-xl text-gray-600 leading-relaxed">
        As dogs age, their nutritional needs change significantly. This guide helps you understand 
        the best dietary choices for your senior companion to ensure they stay healthy and 
        comfortable in their golden years.
      </p>
    </header>
  );
}