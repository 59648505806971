import React from 'react';
import { UserCheck } from 'lucide-react';

export function ExpertRecommendations() {
  const recommendations = [
    {
      title: "Consult Your Veterinarian",
      description: "Discuss your dog's specific needs, health history, and potential risks before switching diets."
    },
    {
      title: "Consider Your Dog's Health Status",
      description: "Evaluate any existing conditions, allergies, or sensitivities that might influence diet choice."
    },
    {
      title: "Monitor for Changes",
      description: "Watch for changes in energy, coat condition, digestion, and overall health after dietary changes."
    },
    {
      title: "Read Labels Carefully",
      description: "Look for foods that meet AAFCO standards and contain high-quality ingredients."
    }
  ];

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Expert Recommendations</h2>
      
      <div className="bg-emerald-50 rounded-lg p-8 mb-8">
        <div className="flex items-center mb-6">
          <UserCheck className="h-8 w-8 text-emerald-600 mr-3" />
          <h3 className="text-xl font-semibold text-gray-900">Professional Guidance</h3>
        </div>
        
        <div className="grid gap-6 md:grid-cols-2">
          {recommendations.map((rec) => (
            <div key={rec.title} className="bg-white rounded-lg p-4 shadow-md">
              <h4 className="font-medium text-gray-900 mb-2">{rec.title}</h4>
              <p className="text-gray-600">{rec.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="prose max-w-none text-gray-600">
        <blockquote className="border-l-4 border-emerald-500 pl-4 italic">
          "The decision to feed grain-free should be based on individual factors including your dog's age, 
          health status, and specific nutritional needs. There's no one-size-fits-all answer, but working 
          with your veterinarian can help determine the best diet for your pet."
          <footer className="mt-2 text-gray-900 font-medium">
            — Dr. Michael Chen, DVM, PhD
          </footer>
        </blockquote>
      </div>
    </section>
  );
}