import React from 'react';

export function ExpertAdvice() {
  return (
    <div className="py-16 bg-emerald-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <img
              className="rounded-lg shadow-xl"
              src="https://images.unsplash.com/photo-1559839734-2b71ea197ec2?auto=format&fit=crop&q=80&w=1024"
              alt="Female veterinarian with dog"
            />
          </div>
          <div className="mt-12 lg:mt-0">
            <h2 className="text-3xl font-bold text-gray-900">Expert Veterinary Advice</h2>
            <div className="mt-6 space-y-6 text-gray-600">
              <p>
                "When choosing a dog food type, consider your pet's age, size, activity level, and any health conditions. There's no one-size-fits-all solution, but quality ingredients and balanced nutrition should always be priorities."
              </p>
              <p>
                "Regular consultation with your veterinarian can help you make the best dietary choices as your dog's needs change throughout their life."
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}