import React from 'react';
import { Stethoscope } from 'lucide-react';

export function VetConsultation() {
  return (
    <div className="py-16 bg-emerald-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <img
              className="rounded-lg shadow-xl"
              src="https://images.unsplash.com/photo-1629909613654-28e377c37b09?auto=format&fit=crop&q=80&w=1024"
              alt="Veterinarian consulting with pet owner"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/20 to-transparent rounded-lg"></div>
          </div>
          <div className="mt-12 lg:mt-0">
            <div className="inline-flex items-center justify-center p-3 bg-emerald-100 rounded-xl">
              <Stethoscope className="h-6 w-6 text-emerald-600" />
            </div>
            <h2 className="mt-4 text-3xl font-bold text-gray-900">Professional Guidance</h2>
            <div className="mt-6 space-y-6 text-gray-600">
              <p className="text-lg">
                "When dealing with special dietary needs, it's crucial to work closely with your veterinarian. They can:
              </p>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2 mt-2"></span>
                  <span>Perform necessary diagnostic tests to identify specific health issues</span>
                </li>
                <li className="flex items-start">
                  <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2 mt-2"></span>
                  <span>Create a customized nutrition plan based on your dog's condition</span>
                </li>
                <li className="flex items-start">
                  <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2 mt-2"></span>
                  <span>Monitor progress and adjust recommendations as needed</span>
                </li>
                <li className="flex items-start">
                  <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2 mt-2"></span>
                  <span>Provide guidance on transitioning between different diets</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}