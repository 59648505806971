import React from 'react';
import { Check, X, Minus } from 'lucide-react';

export function ComparisonTable() {
  const features = [
    { name: 'Organic Ingredients', premium: 'Yes', standard: 'Partial', budget: 'No' },
    { name: 'Human-Grade Meat', premium: 'Yes', standard: 'No', budget: 'No' },
    { name: 'Non-GMO', premium: 'Yes', standard: 'Partial', budget: 'No' },
    { name: 'Artificial Preservatives', premium: 'No', standard: 'Some', budget: 'Yes' },
    { name: 'Limited Ingredients', premium: 'Available', standard: 'Limited', budget: 'No' },
    { name: 'Price per Pound', premium: 'High', standard: 'Medium', budget: 'Low' }
  ];

  const getRatingIcon = (rating: string) => {
    switch (rating.toLowerCase()) {
      case 'yes':
        return <div className="flex justify-center"><Check className="w-5 h-5 text-green-500" /></div>;
      case 'no':
        return <div className="flex justify-center"><X className="w-5 h-5 text-red-500" /></div>;
      default:
        return <span className="text-gray-900">{rating}</span>;
    }
  };

  return (
    <div className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">Brand Tier Comparison</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-lg shadow-lg">
            <thead>
              <tr className="bg-emerald-50">
                <th className="py-4 px-6 text-left text-sm font-semibold text-gray-900">Feature</th>
                <th className="py-4 px-6 text-center text-sm font-semibold text-gray-900">Premium Brands</th>
                <th className="py-4 px-6 text-center text-sm font-semibold text-gray-900">Standard Brands</th>
                <th className="py-4 px-6 text-center text-sm font-semibold text-gray-900">Budget Brands</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {features.map((feature) => (
                <tr key={feature.name} className="hover:bg-gray-50">
                  <td className="py-4 px-6 text-sm font-medium text-gray-900">{feature.name}</td>
                  <td className="py-4 px-6 text-center text-sm text-gray-600">{getRatingIcon(feature.premium)}</td>
                  <td className="py-4 px-6 text-center text-sm text-gray-600">{getRatingIcon(feature.standard)}</td>
                  <td className="py-4 px-6 text-center text-sm text-gray-600">{getRatingIcon(feature.budget)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}