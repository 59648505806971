import React from 'react';
import { HelpCircle } from 'lucide-react';

export function BrandSelection() {
  const tips = [
    "Consider your dog's age, size, and activity level",
    "Look for brands that meet AAFCO standards",
    "Check for real meat as the first ingredient",
    "Avoid artificial preservatives and fillers",
    "Consider any special dietary needs or restrictions",
    "Read customer reviews and ratings",
    "Consult with your veterinarian"
  ];

  return (
    <div className="py-16 bg-emerald-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h2 className="text-3xl font-bold text-gray-900">How to Choose the Right Brand</h2>
            <div className="mt-8 space-y-4">
              {tips.map((tip, index) => (
                <div key={index} className="flex items-start">
                  <HelpCircle className="h-6 w-6 text-emerald-600 mt-1 mr-3" />
                  <p className="text-gray-600">{tip}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-12 lg:mt-0">
            <img
              className="rounded-lg shadow-xl"
              src="https://images.unsplash.com/photo-1600369671236-e74521d4b6ad?auto=format&fit=crop&q=80&w=1024"
              alt="Dog food selection process"
            />
          </div>
        </div>
      </div>
    </div>
  );
}