import { Brand } from '../types/brand';

export const brands: Brand[] = [
  {
    id: 'amazon-wag',
    name: "Amazon Brand - Wag Dry Dog Food",
    description: "High-quality nutrition at an affordable price point",
    features: ['Salmon & Sweet Potato recipe', 'Affordable premium option', 'Excellent customer reviews'],
    tags: ['affordable', 'premium', 'salmon', 'sweet potato']
  },
  {
    id: 'acana-wild-atlantic',
    name: "ACANA Highest Protein Dry Dog Food",
    description: "Premium protein-rich formula with wild-caught fish",
    features: ['High protein content', 'Wild-caught fish', 'Grain-free formula'],
    tags: ['premium', 'high-protein', 'grain-free', 'fish']
  },
  {
    id: 'honest-kitchen-dehydrated',
    name: "The Honest Kitchen Dehydrated Whole Grain",
    description: "Human-grade dehydrated dog food with whole grains",
    features: ['Human-grade ingredients', 'Dehydrated formula', 'Whole grain nutrition'],
    tags: ['human-grade', 'dehydrated', 'whole-grain', 'beef']
  },
  {
    id: 'acana-wholesome-grains',
    name: "ACANA Wholesome Grains",
    description: "Balanced nutrition with quality grains and red meat",
    features: ['Red meat protein', 'Wholesome grains', 'Gluten-free'],
    tags: ['wholesome', 'grains', 'red-meat', 'balanced']
  },
  {
    id: 'open-farm',
    name: "Open Farm Grain-Free",
    description: "Ethically sourced ingredients for picky eaters",
    features: ['Ethically sourced', 'Grain-free', 'High palatability'],
    tags: ['ethical', 'grain-free', 'picky-eaters']
  },
  {
    id: 'orijen-amazing-grains',
    name: "ORIJEN Amazing Grains Original",
    description: "Premium formula for skin and coat health",
    features: ['Skin health support', 'Digestive benefits', 'Quality grains'],
    tags: ['skin-health', 'digestion', 'grains']
  },
  {
    id: 'wellness-complete-small-breed',
    name: "Wellness Complete Health Small Breed",
    description: "Specialized nutrition for small breed dogs",
    features: ['Small breed formula', 'Veterinarian recommended', 'Complete nutrition'],
    tags: ['small-breed', 'complete-nutrition', 'vet-recommended']
  },
  {
    id: 'acana-butchers',
    name: "ACANA Butcher's Favorites",
    description: "Premium meat-focused recipe",
    features: ['High-quality meats', 'Premium ingredients', 'Excellent taste'],
    tags: ['premium', 'meat-rich', 'palatability']
  },
  {
    id: 'honest-kitchen-clusters',
    name: "The Honest Kitchen Whole Food Clusters",
    description: "Clustered whole food nutrition for small breeds",
    features: ['Whole food ingredients', 'Small breed specific', 'Human-grade'],
    tags: ['whole-food', 'small-breed', 'clusters']
  },
  {
    id: 'acana-singles',
    name: "ACANA Singles Limited Ingredient",
    description: "Simple recipes for sensitive dogs",
    features: ['Limited ingredients', 'Single protein', 'Allergy friendly'],
    tags: ['limited-ingredient', 'sensitive', 'single-protein']
  },
  {
    id: 'instinct-raw-boost',
    name: "Instinct Raw Boost",
    description: "Grain-free kibble with freeze-dried raw pieces",
    features: ['Raw nutrition', 'Grain-free', 'High protein'],
    tags: ['raw', 'grain-free', 'freeze-dried']
  },
  {
    id: 'blue-buffalo-senior',
    name: "Blue Buffalo Life Protection Senior",
    description: "Age-appropriate nutrition for senior dogs",
    features: ['Senior formula', 'Joint support', 'Immune health'],
    tags: ['senior', 'joint-health', 'immune-support']
  },
  {
    id: 'hills-science-diet',
    name: "Hill's Science Diet Small Bites",
    description: "Veterinarian-recommended balanced nutrition",
    features: ['Vet recommended', 'Small kibble size', 'Balanced nutrition'],
    tags: ['vet-recommended', 'small-bites', 'science-based']
  },
  {
    id: 'purina-pro-plan',
    name: "Purina Pro Plan High Protein",
    description: "Performance nutrition with real meat first",
    features: ['High protein', 'Real meat first', 'Athletic support'],
    tags: ['high-protein', 'performance', 'athletic']
  },
  {
    id: 'royal-canin-medium',
    name: "Royal Canin Medium Adult",
    description: "Breed-specific nutrition for medium dogs",
    features: ['Medium breed specific', 'Optimal kibble', 'Precise nutrition'],
    tags: ['medium-breed', 'breed-specific', 'precise']
  },
  {
    id: 'merrick-grain-free',
    name: "Merrick Grain Free",
    description: "Premium grain-free with deboned meat",
    features: ['Deboned meat', 'Grain-free', 'Joint support'],
    tags: ['grain-free', 'deboned-meat', 'joint-health']
  },
  {
    id: 'nutro-natural-choice',
    name: "Nutro Natural Choice Large Breed",
    description: "Natural nutrition for large breeds",
    features: ['Non-GMO', 'Large breed formula', 'Natural ingredients'],
    tags: ['large-breed', 'natural', 'non-gmo']
  },
  {
    id: 'taste-of-the-wild',
    name: "Taste of the Wild High Prairie",
    description: "Grain-free recipe with roasted meats",
    features: ['Roasted meats', 'Probiotics', 'Grain-free'],
    tags: ['grain-free', 'roasted', 'probiotics']
  },
  {
    id: 'iams-proactive',
    name: "Iams ProActive Health MiniChunks",
    description: "Veterinarian-recommended complete nutrition",
    features: ['Real chicken', 'Complete nutrition', 'Vet recommended'],
    tags: ['vet-recommended', 'chicken', 'complete']
  },
  {
    id: 'wellness-core',
    name: "Wellness CORE Natural Grain Free",
    description: "Premium protein-focused grain-free nutrition",
    features: ['High protein', 'Grain-free', 'Probiotics'],
    tags: ['grain-free', 'high-protein', 'premium']
  },
  {
    id: 'diamond-naturals',
    name: "Diamond Naturals Real Meat",
    description: "Real meat recipe with superfoods",
    features: ['Real meat', 'Superfoods', 'Complete nutrition'],
    tags: ['real-meat', 'superfoods', 'natural']
  },
  {
    id: 'fromm-gold',
    name: "Fromm Family Foods Gold",
    description: "Family-made premium dog nutrition",
    features: ['Family owned', 'Select proteins', 'Premium quality'],
    tags: ['family-owned', 'premium', 'quality']
  },
  {
    id: 'natures-recipe',
    name: "Nature's Recipe Grain Free",
    description: "Easy to digest formula for sensitive dogs",
    features: ['Easy digestion', 'Sweet potato', 'Grain-free'],
    tags: ['sensitive', 'digestive', 'grain-free']
  },
  {
    id: 'rachael-ray-nutrish',
    name: "Rachael Ray Nutrish Premium",
    description: "Natural ingredients with added vitamins",
    features: ['Real meat first', 'Natural ingredients', 'No preservatives'],
    tags: ['natural', 'real-meat', 'preservative-free']
  },
  {
    id: 'whole-earth-farms',
    name: "Whole Earth Farms Grain Free",
    description: "Holistic nutrition made in USA",
    features: ['USA made', 'Holistic recipe', 'Quality proteins'],
    tags: ['holistic', 'usa-made', 'grain-free']
  },
  {
    id: 'victor-purpose',
    name: "Victor Purpose Nutra Pro",
    description: "High-protein formula for active dogs",
    features: ['High protein', 'Active formula', 'Sport nutrition'],
    tags: ['high-protein', 'active', 'sport']
  },
  {
    id: 'earthborn-holistic',
    name: "Earthborn Holistic Great Plains",
    description: "Grain-free red meat formula",
    features: ['Red meat protein', 'Grain-free', 'Antioxidants'],
    tags: ['grain-free', 'red-meat', 'holistic']
  },
  {
    id: 'canidae-pure',
    name: "Canidae PURE Limited Ingredient",
    description: "Simple limited ingredient recipes",
    features: ['Limited ingredients', 'Sensitive formula', 'Simple recipe'],
    tags: ['limited-ingredient', 'sensitive', 'pure']
  },
  {
    id: 'natural-balance-limited',
    name: "Natural Balance Limited Ingredient",
    description: "Limited ingredient diet for sensitivities",
    features: ['Limited ingredients', 'Allergy friendly', 'Simple formula'],
    tags: ['limited-ingredient', 'allergies', 'simple']
  },
  {
    id: 'solid-gold',
    name: "Solid Gold Wolf King",
    description: "Holistic nutrition with bison protein",
    features: ['Bison protein', 'Holistic', 'Superfoods'],
    tags: ['holistic', 'bison', 'superfoods']
  },
  {
    id: 'nulo-freestyle',
    name: "Nulo Freestyle Limited Plus",
    description: "Limited ingredient with low glycemic index",
    features: ['Single protein', 'Low glycemic', 'Probiotics'],
    tags: ['limited-ingredient', 'low-glycemic', 'probiotics']
  },
  {
    id: 'zignature-limited',
    name: "Zignature Limited Ingredient",
    description: "Novel protein options for sensitivities",
    features: ['Novel proteins', 'Limited ingredients', 'Hypoallergenic'],
    tags: ['limited-ingredient', 'novel-protein', 'hypoallergenic']
  }
];