import React from 'react';
import { BlogHeader } from '../components/blog/BlogHeader';
import { BackToBlog } from '../components/blog/BackToBlog';
import { LabelComponents } from '../components/blog/LabelComponents';
import { NutritionalAnalysis } from '../components/blog/NutritionalAnalysis';
import { IngredientGuide } from '../components/blog/IngredientGuide';
import { CertificationGuide } from '../components/blog/CertificationGuide';
import { CommonMisconceptions } from '../components/blog/CommonMisconceptions';
import { BlogPostLayout } from '../components/layouts/BlogPostLayout';

export function FoodLabelsBlog() {
  return (
    <BlogPostLayout>
      <div>
        <BackToBlog />
        <BlogHeader />
      </div>
      <LabelComponents />
      <NutritionalAnalysis />
      <IngredientGuide />
      <CertificationGuide />
      <CommonMisconceptions />
    </BlogPostLayout>
  );
}