import React from 'react';

interface FeatureTooltipProps {
  features: string[];
  isVisible: boolean;
}

export function FeatureTooltip({ features, isVisible }: FeatureTooltipProps) {
  if (!isVisible) return null;

  return (
    <div 
      className="absolute left-1/2 bottom-full mb-3 -translate-x-1/2 z-50 w-72"
      style={{ 
        opacity: isVisible ? 1 : 0,
        visibility: isVisible ? 'visible' : 'hidden',
        transform: `translate(-50%, ${isVisible ? '0' : '10px'})`,
        transition: 'all 0.2s ease-in-out'
      }}
    >
      <div className="bg-gray-900 rounded-xl shadow-xl p-4">
        <div className="relative">
          <h4 className="font-semibold text-white mb-3 pb-2 border-b border-gray-700">
            Key Features
          </h4>
          <ul className="space-y-2">
            {features.map((feature) => (
              <li key={feature} className="flex items-start text-gray-200">
                <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2 mt-2 flex-shrink-0"></span>
                <span className="text-sm">{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* Arrow pointer */}
      <div className="absolute left-1/2 bottom-0 -mb-2 -translate-x-1/2">
        <div className="border-8 border-transparent border-t-gray-900" />
      </div>
    </div>
  );
}