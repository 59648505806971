import React from 'react';
import { Check, X } from 'lucide-react';

export function ComparisonTable() {
  const features = [
    { 
      name: 'Convenience',
      ratings: {
        'Dry Kibble': 'High',
        'Wet Food': 'Medium',
        'Raw Diet': 'Low',
        'Fresh Cooked': 'Medium',
        'Freeze-Dried': 'Medium',
        'Dehydrated': 'Low'
      }
    },
    { 
      name: 'Cost',
      ratings: {
        'Dry Kibble': 'Low',
        'Wet Food': 'Medium',
        'Raw Diet': 'High',
        'Fresh Cooked': 'High',
        'Freeze-Dried': 'Very High',
        'Dehydrated': 'High'
      }
    },
    { 
      name: 'Shelf Life',
      ratings: {
        'Dry Kibble': 'Long',
        'Wet Food': 'Medium',
        'Raw Diet': 'Short',
        'Fresh Cooked': 'Short',
        'Freeze-Dried': 'Long',
        'Dehydrated': 'Long'
      }
    },
    { 
      name: 'Processing Level',
      ratings: {
        'Dry Kibble': 'High',
        'Wet Food': 'Medium',
        'Raw Diet': 'Minimal',
        'Fresh Cooked': 'Low',
        'Freeze-Dried': 'Low',
        'Dehydrated': 'Low'
      }
    },
    { 
      name: 'Nutrient Retention',
      ratings: {
        'Dry Kibble': 'Medium',
        'Wet Food': 'Medium',
        'Raw Diet': 'High',
        'Fresh Cooked': 'High',
        'Freeze-Dried': 'High',
        'Dehydrated': 'High'
      }
    },
    { 
      name: 'Storage Requirements',
      ratings: {
        'Dry Kibble': 'Simple',
        'Wet Food': 'Simple',
        'Raw Diet': 'Freezer',
        'Fresh Cooked': 'Freezer',
        'Freeze-Dried': 'Simple',
        'Dehydrated': 'Simple'
      }
    },
    { 
      name: 'Preparation Needed',
      ratings: {
        'Dry Kibble': 'None',
        'Wet Food': 'None',
        'Raw Diet': 'High',
        'Fresh Cooked': 'Medium',
        'Freeze-Dried': 'Medium',
        'Dehydrated': 'High'
      }
    }
  ];

  const foodTypes = ['Dry Kibble', 'Wet Food', 'Raw Diet', 'Fresh Cooked', 'Freeze-Dried', 'Dehydrated'];

  return (
    <div className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">Comparison Chart</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-lg shadow-lg">
            <thead className="bg-emerald-50">
              <tr>
                <th className="py-4 px-6 text-left text-sm font-semibold text-gray-900">Feature</th>
                {foodTypes.map((type) => (
                  <th key={type} className="py-4 px-6 text-center text-sm font-semibold text-gray-900">
                    {type}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {features.map((feature) => (
                <tr key={feature.name} className="hover:bg-gray-50">
                  <td className="py-4 px-6 text-sm font-medium text-gray-900">{feature.name}</td>
                  {foodTypes.map((type) => (
                    <td key={`${feature.name}-${type}`} className="py-4 px-6 text-center text-sm text-gray-600">
                      {feature.ratings[type as keyof typeof feature.ratings]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}