import React from 'react';
import { PageLayout } from './PageLayout';

interface CategoryLayoutProps {
  children: React.ReactNode;
  fullWidth?: boolean;
}

export function CategoryLayout({ children, fullWidth = false }: CategoryLayoutProps) {
  const childrenArray = React.Children.toArray(children);
  const heroSection = childrenArray[0];
  const remainingContent = childrenArray.slice(1);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero section - full width */}
      {heroSection}
      
      {/* Main content - contained width */}
      <PageLayout maxWidth={fullWidth ? 'wide' : 'default'}>
        {remainingContent}
      </PageLayout>
    </div>
  );
}