import React from 'react';

interface PageLayoutProps {
  children: React.ReactNode;
  maxWidth?: 'default' | 'wide' | 'full';
  spacing?: 'default' | 'compact' | 'none';
  className?: string;
}

export function PageLayout({ 
  children, 
  maxWidth = 'default',
  spacing = 'default',
  className = ''
}: PageLayoutProps) {
  const maxWidthClasses = {
    default: 'max-w-4xl',
    wide: 'max-w-7xl',
    full: 'max-w-full'
  };

  const spacingClasses = {
    default: 'py-16 px-4 sm:px-6 lg:px-8',
    compact: 'py-8 px-4 sm:px-6 lg:px-8',
    none: ''
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className={`mx-auto ${maxWidthClasses[maxWidth]} ${spacingClasses[spacing]} ${className}`}>
        {children}
      </div>
    </div>
  );
}