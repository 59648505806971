import React from 'react';
import { Wheat } from 'lucide-react';

export function WhatIsGrainFree() {
  const commonReplacements = [
    {
      ingredient: "Sweet Potatoes",
      role: "Complex carbohydrates and fiber source",
      benefits: "Rich in vitamins A and C, good for digestion"
    },
    {
      ingredient: "Legumes",
      role: "Protein and carbohydrate source",
      benefits: "High in fiber and minerals"
    },
    {
      ingredient: "Peas",
      role: "Protein and carbohydrate source",
      benefits: "Good source of vitamins and minerals"
    },
    {
      ingredient: "Tapioca",
      role: "Binding agent and carbohydrate source",
      benefits: "Easy to digest, gluten-free"
    }
  ];

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">What is Grain-Free Dog Food?</h2>
      
      <div className="prose max-w-none text-gray-600 mb-8">
        <p className="mb-4">
          Grain-free dog food is formulated without common grains such as wheat, corn, rice, soy, and barley. 
          Instead, these diets use alternative carbohydrate sources to provide energy and other nutrients 
          typically found in grains.
        </p>
        <p>
          While traditional dog foods have used grains for thousands of years, grain-free options have gained 
          popularity as part of a broader trend toward what some consider more "natural" or "ancestral" diets 
          for pets.
        </p>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6 mt-8">
        <h3 className="text-xl font-semibold text-gray-900 mb-6 flex items-center">
          <Wheat className="h-6 w-6 text-emerald-600 mr-3" />
          Common Grain Replacements
        </h3>
        
        <div className="grid gap-6 md:grid-cols-2">
          {commonReplacements.map((item) => (
            <div key={item.ingredient} className="bg-emerald-50 rounded-lg p-4">
              <h4 className="font-medium text-emerald-800 mb-2">{item.ingredient}</h4>
              <p className="text-sm text-gray-700 mb-2">
                <span className="font-medium">Role: </span>
                {item.role}
              </p>
              <p className="text-sm text-gray-700">
                <span className="font-medium">Benefits: </span>
                {item.benefits}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}