import React from 'react';
import { LifeStagesHero } from '../components/life-stages/LifeStagesHero';
import { StageCards } from '../components/life-stages/StageCards';
import { NutritionalNeeds } from '../components/life-stages/NutritionalNeeds';
import { TransitionGuide } from '../components/life-stages/TransitionGuide';
import { ExpertTips } from '../components/life-stages/ExpertTips';

export function LifeStages() {
  return (
    <div className="min-h-screen bg-gray-50">
      <LifeStagesHero />
      <StageCards />
      <NutritionalNeeds />
      <TransitionGuide />
      <ExpertTips />
    </div>
  );
}