import React from 'react';
import { Award, Shield } from 'lucide-react';

export function CertificationGuide() {
  const certifications = [
    {
      name: "AAFCO Statement",
      description: "Indicates the food meets nutritional requirements for specific life stages.",
      importance: "Essential for confirming nutritional adequacy"
    },
    {
      name: "USDA Organic",
      description: "Certifies organic ingredients and processing methods.",
      importance: "Ensures organic standards are met"
    },
    {
      name: "Quality Assurance",
      description: "Manufacturing facility certifications and quality control.",
      importance: "Indicates safety and consistency"
    }
  ];

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Certifications & Standards</h2>
      
      <div className="grid gap-6 md:grid-cols-3">
        {certifications.map((cert) => (
          <div key={cert.name} className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              {cert.name.includes("AAFCO") ? (
                <Shield className="h-6 w-6 text-emerald-600 mr-3" />
              ) : (
                <Award className="h-6 w-6 text-emerald-600 mr-3" />
              )}
              <h3 className="text-xl font-semibold text-gray-900">{cert.name}</h3>
            </div>
            <p className="text-gray-600 mb-4">{cert.description}</p>
            <p className="text-sm text-emerald-600 font-medium">{cert.importance}</p>
          </div>
        ))}
      </div>
    </section>
  );
}