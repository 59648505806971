import React from 'react';
import { Bone } from 'lucide-react';

export function FoodTypeHero() {
  return (
    <div className="relative bg-emerald-50 py-16 sm:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
              Understanding Dog Food Types
            </h1>
            <p className="mt-6 text-xl text-gray-500">
              From kibble to raw food, discover the pros and cons of each diet type to make an informed decision for your pet's nutrition.
            </p>
            <div className="mt-8 flex items-center space-x-4">
              <Bone className="h-6 w-6 text-emerald-600" />
              <span className="text-lg font-medium text-gray-900">Expert-reviewed nutrition information</span>
            </div>
          </div>
          <div className="mt-12 lg:mt-0">
            <img
              className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              src="https://images.unsplash.com/photo-1589924691995-400dc9ecc119?auto=format&fit=crop&q=80&w=1024"
              alt="Different types of dog food"
            />
          </div>
        </div>
      </div>
    </div>
  );
}