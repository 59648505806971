// Global state for ad management
let adSlots = new Set<string>();
let isInitialized = false;
let initializationQueue: string[] = [];
let isProcessingQueue = false;

export function initializeGlobalAdState(): void {
  if (!isInitialized) {
    cleanupAdSlots();
    isInitialized = true;
  }
}

export function registerAdSlot(slot: string): boolean {
  if (adSlots.has(slot)) {
    return false;
  }
  
  // Add to initialization queue
  initializationQueue.push(slot);
  adSlots.add(slot);
  
  // Start processing queue if not already processing
  if (!isProcessingQueue) {
    processQueue();
  }
  
  return true;
}

async function processQueue() {
  if (isProcessingQueue || initializationQueue.length === 0) {
    return;
  }
  
  isProcessingQueue = true;
  
  while (initializationQueue.length > 0) {
    // Process one ad at a time with delay
    await new Promise(resolve => setTimeout(resolve, 200));
    initializationQueue.shift();
  }
  
  isProcessingQueue = false;
}

export function cleanupAdSlots(): void {
  // Remove all ad elements from DOM
  document.querySelectorAll('ins.adsbygoogle').forEach(el => {
    el.remove();
  });
  
  // Clear state
  adSlots.clear();
  initializationQueue = [];
  isProcessingQueue = false;
  isInitialized = false;
  
  // Reset AdSense state
  // @ts-ignore
  window.adsbygoogle = [];
}