import React from 'react';
import { SpecialNeedsHero } from '../components/special-needs/SpecialNeedsHero';
import { ConditionCards } from '../components/special-needs/ConditionCards';
import { IngredientWatch } from '../components/special-needs/IngredientWatch';
import { VetConsultation } from '../components/special-needs/VetConsultation';

export function SpecialNeeds() {
  return (
    <div className="min-h-screen bg-gray-50">
      <SpecialNeedsHero />
      <ConditionCards />
      <IngredientWatch />
      <VetConsultation />
    </div>
  );
}