import React from 'react';
import { Baby, Dog, Heart } from 'lucide-react';

export function StageCards() {
  const stages = [
    {
      icon: <Baby className="h-8 w-8" />,
      title: 'Puppy (0-1 year)',
      description: 'High-energy nutrition for growth and development',
      keyPoints: [
        'Higher protein and fat content',
        'DHA for brain development',
        'Calcium for bone growth',
        '4-5 meals per day'
      ],
      image: 'https://images.unsplash.com/photo-1546527868-ccb7ee7dfa6a?auto=format&fit=crop&q=80&w=800'
    },
    {
      icon: <Dog className="h-8 w-8" />,
      title: 'Adult (1-7 years)',
      description: 'Balanced nutrition for maintenance and activity',
      keyPoints: [
        'Balanced protein and fat levels',
        'Joint support nutrients',
        'Weight management',
        '2-3 meals per day'
      ],
      image: 'https://images.unsplash.com/photo-1477884213360-7e9d7dcc1e48?auto=format&fit=crop&q=80&w=800'
    },
    {
      icon: <Heart className="h-8 w-8" />,
      title: 'Senior (7+ years)',
      description: 'Specialized nutrition for aging support',
      keyPoints: [
        'Lower calorie content',
        'Glucosamine for joints',
        'Antioxidants for immunity',
        'Easy-to-digest ingredients'
      ],
      image: 'https://images.unsplash.com/photo-1518717758536-85ae29035b6d?auto=format&fit=crop&q=80&w=800'
    }
  ];

  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Life Stages Overview</h2>
          <p className="mt-4 text-lg text-gray-600">Understanding your dog's nutritional needs at every age</p>
        </div>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {stages.map((stage) => (
            <div key={stage.title} className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
              <div className="aspect-w-16 aspect-h-9">
                <img className="object-cover w-full h-48" src={stage.image} alt={stage.title} />
              </div>
              <div className="p-6">
                <div className="inline-flex p-3 rounded-xl bg-emerald-50 text-emerald-600">
                  {stage.icon}
                </div>
                <h3 className="mt-4 text-xl font-semibold text-gray-900">{stage.title}</h3>
                <p className="mt-2 text-gray-600">{stage.description}</p>
                <ul className="mt-4 space-y-2">
                  {stage.keyPoints.map((point) => (
                    <li key={point} className="flex items-center text-gray-600">
                      <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2"></span>
                      {point}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}