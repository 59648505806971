import React from 'react';
import { useNavigation } from '../contexts/NavigationContext';

export function RecentArticles() {
  const { navigate } = useNavigation();
  
  const articles = [
    {
      title: 'Understanding Dog Food Labels: A Complete Guide',
      category: 'Education',
      image: 'https://images.unsplash.com/photo-1568640347023-a616a30bc3bd?auto=format&fit=crop&w=800&q=80',
      excerpt: 'Learn how to decode dog food labels and make informed decisions about your pet\'s nutrition.',
      page: 'blog-food-labels'
    },
    {
      title: 'The Truth About Grain-Free Dog Food',
      category: 'Nutrition',
      image: 'https://images.unsplash.com/photo-1548199973-03cce0bbc87b?auto=format&fit=crop&w=800&q=80',
      excerpt: 'Explore the pros and cons of grain-free diets and what recent research tells us.',
      page: 'blog-grain-free'
    },
    {
      title: 'Best Foods for Senior Dogs',
      category: 'Life Stages',
      image: 'https://images.unsplash.com/photo-1518717758536-85ae29035b6d?auto=format&fit=crop&w=800&q=80',
      excerpt: 'Discover the nutritional needs of aging dogs and top food recommendations.',
      page: 'blog-senior-dogs'
    }
  ];

  return (
    <div className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">Latest Articles</h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Stay informed with our latest research and insights
          </p>
        </div>

        <div className="mt-12 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {articles.map((article) => (
            <div 
              key={article.title}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden bg-white cursor-pointer hover:shadow-xl transition-shadow duration-300"
              onClick={() => navigate(article.page)}
            >
              <div className="flex-shrink-0">
                <img 
                  className="h-48 w-full object-cover transform hover:scale-105 transition-transform duration-300" 
                  src={article.image} 
                  alt={article.title}
                />
              </div>
              <div className="flex-1 p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-emerald-600 bg-emerald-50 inline-block px-3 py-1 rounded-full">
                    {article.category}
                  </p>
                  <h3 className="mt-2 text-xl font-semibold text-gray-900 hover:text-emerald-600 transition-colors duration-200">
                    {article.title}
                  </h3>
                  <p className="mt-3 text-base text-gray-500">{article.excerpt}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}