import { FoodType } from '../types/food';

export const foodTypes: FoodType[] = [
  {
    id: 'dry-kibble',
    name: 'Dry Kibble',
    description: 'Traditional dry food that\'s complete and balanced for daily feeding.',
    pros: ['Convenient', 'Cost-effective', 'Long shelf life', 'Dental benefits'],
    cons: ['Less moisture content', 'More processed', 'Variable quality'],
    tags: ['dry food', 'kibble', 'dental health', 'convenient']
  },
  {
    id: 'wet-food',
    name: 'Wet Food',
    description: 'Canned food with high moisture content, ideal for hydration.',
    pros: ['High moisture', 'Palatable', 'Less processed', 'Good for seniors'],
    cons: ['More expensive', 'Shorter shelf life', 'Dental concerns'],
    tags: ['wet food', 'canned food', 'hydration', 'palatability']
  },
  {
    id: 'raw-diet',
    name: 'Raw Diet',
    description: 'Uncooked meat, bones, and organs mimicking natural diet.',
    pros: ['Minimally processed', 'Natural nutrients', 'Better digestion'],
    cons: ['Requires careful handling', 'Most expensive', 'Safety concerns'],
    tags: ['raw food', 'BARF diet', 'natural diet', 'fresh food']
  },
  {
    id: 'fresh-cooked',
    name: 'Fresh Cooked',
    description: 'Gently cooked fresh ingredients, often delivered frozen.',
    pros: ['Minimally processed', 'High palatability', 'Human-grade ingredients'],
    cons: ['Expensive', 'Requires freezer space', 'Short shelf life'],
    tags: ['fresh food', 'human-grade', 'cooked', 'premium']
  },
  {
    id: 'freeze-dried',
    name: 'Freeze-Dried',
    description: 'Raw food that\'s freeze-dried to preserve nutrients.',
    pros: ['Nutrient retention', 'Long shelf life', 'Lightweight'],
    cons: ['Very expensive', 'Requires rehydration', 'Limited availability'],
    tags: ['freeze-dried', 'raw', 'preserved', 'lightweight']
  },
  {
    id: 'dehydrated',
    name: 'Dehydrated',
    description: 'Gently dried food that\'s rehydrated before serving.',
    pros: ['Less processed', 'Long shelf life', 'Easy storage'],
    cons: ['Requires preparation', 'More expensive', 'Texture changes'],
    tags: ['dehydrated', 'dried', 'preserved', 'rehydrated']
  }
];