import React from 'react';
import { AlertCircle } from 'lucide-react';

export function CommonIssues() {
  const issues = [
    {
      title: "Dental Problems",
      description: "Difficulty chewing hard foods",
      solution: "Softer kibble or wet food options"
    },
    {
      title: "Reduced Appetite",
      description: "Less interest in food",
      solution: "Enhanced palatability and smaller portions"
    },
    {
      title: "Digestive Sensitivity",
      description: "More prone to upset stomach",
      solution: "Easily digestible ingredients and probiotics"
    },
    {
      title: "Joint Stiffness",
      description: "Mobility issues affecting eating",
      solution: "Elevated food bowls and accessible feeding areas"
    }
  ];

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Common Age-Related Issues</h2>
      
      <div className="grid gap-6 md:grid-cols-2">
        {issues.map((issue) => (
          <div key={issue.title} className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              <AlertCircle className="h-6 w-6 text-amber-500 mr-3" />
              <h3 className="text-xl font-semibold text-gray-900">{issue.title}</h3>
            </div>
            <p className="text-gray-600 mb-3">{issue.description}</p>
            <div className="bg-emerald-50 p-3 rounded-md">
              <span className="font-medium text-emerald-800">Solution: </span>
              <span className="text-emerald-700">{issue.solution}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}