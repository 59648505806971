import React from 'react';
import { foodTypes } from '../../data/foodTypes';
import { Circle, CheckCircle2, AlertCircle } from 'lucide-react';

export function DietTypes() {
  const images = {
    'dry-kibble': 'https://s3.envato.com/files/226036416/02_05_17_5DMIII_008018.jpg',
    'wet-food': 'https://www.dogfoodadvisor.com/wp-content/uploads/2020/09/Farmers-Dog-Wet-Food.png',
    'raw-diet': 'https://pawleaks.com/wp-content/uploads/2022/05/dog-raw-diet-bowl.jpg',
    'fresh-cooked': 'https://www.dogfoodadvisor.com/wp-content/uploads/2021/08/Open-Farm-Gently-Cooked-Fresh-Dog-Food.png',
    'freeze-dried': 'https://petfoodreviews.online/wp-content/uploads/2020/11/pet-food-3998919_1920-1536x862.jpg',
    'dehydrated': 'https://www.mypetneedsthat.com/wp-content/uploads/2019/10/Dry-pets-food.jpg'
  };

  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">Popular Dog Food Types</h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {foodTypes.map((diet) => (
            <div key={diet.id} className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
              <div className="aspect-w-16 aspect-h-9">
                <img 
                  className="object-cover w-full h-48" 
                  src={images[diet.id as keyof typeof images]} 
                  alt={diet.name} 
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{diet.name}</h3>
                <p className="text-gray-600 mb-4">{diet.description}</p>
                
                <div className="space-y-4">
                  <div>
                    <h4 className="flex items-center text-green-600 font-medium mb-2">
                      <CheckCircle2 className="h-5 w-5 mr-2" />
                      Advantages
                    </h4>
                    <ul className="pl-7 space-y-1">
                      {diet.pros.map((pro) => (
                        <li key={pro} className="text-gray-600">{pro}</li>
                      ))}
                    </ul>
                  </div>
                  
                  <div>
                    <h4 className="flex items-center text-amber-600 font-medium mb-2">
                      <AlertCircle className="h-5 w-5 mr-2" />
                      Considerations
                    </h4>
                    <ul className="pl-7 space-y-1">
                      {diet.cons.map((con) => (
                        <li key={con} className="text-gray-600">{con}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}