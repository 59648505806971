export const brandData = [
  {
    name: "Amazon Brand - Wag Dry Dog Food Salmon & Sweet Potato",
    image_url: "https://m.media-amazon.com/images/I/71j17LT9ZxL._AC_UL320_.jpg",
    review_count: 6655,
    gemini_summary: "Customer reviews praise the product for its excellent nutrition at an affordable price, comparing favorably to brand-name options.",
    link: "https://amzn.to/3PgXBjB"
  },
  {
    name: "ACANA Highest Protein Dry Dog Food, Wild Atlantic",
    image_url: "https://m.media-amazon.com/images/I/71LidlnPTAL._AC_UL320_.jpg",
    review_count: 1192,
    gemini_summary: "ACANA Meadowland Highest Protein Dry Dog Food is highly praised for its premium ingredients, featuring real meat and being grain-free.",
    link: "https://amzn.to/3W0ZgNX"
  },
  {
    name: "The Honest Kitchen Dehydrated Whole Grain Beef Dog Food",
    image_url: "https://m.media-amazon.com/images/I/81KgXnP7ntL._AC_UL320_.jpg",
    review_count: 10148,
    gemini_summary: "Honest Kitchen pet food scores highly in customer reviews for high-quality ingredients and various flavors to prevent boredom among picky dogs.",
    link: "https://amzn.to/41VqPfo"
  },
  {
    name: "ACANA Wholesome Grains Dry Dog Food, Red Meat and Grains",
    image_url: "https://m.media-amazon.com/images/I/71l81GwN4uL._AC_UL320_.jpg",
    review_count: 2067,
    gemini_summary: "ACANA Wholesome Grains Red Meat and Grains kibble is a good balance of quality and price, offering a variety of proteins and gluten-free formula.",
    link: "https://amzn.to/4fyJ2m1"
  },
  {
    name: "Open Farm Grain-Free Dry Dog Food",
    image_url: "https://m.media-amazon.com/images/I/81pvPVUP+CL._AC_UL320_.jpg",
    review_count: 1627,
    gemini_summary: "Open Farm dog food is praised for its appeal to picky eaters and ethically sourced, high-quality ingredients.",
    link: "https://amzn.to/4gBgQAo"
  },
  {
    name: "ORIJEN Amazing Grains Original Dry Dog Food",
    image_url: "https://m.media-amazon.com/images/I/71fIyY38DYL._AC_UL320_.jpg",
    review_count: 1092,
    gemini_summary: "Orijen dog food is highly praised for its positive impact on skin health, reduced gas, and improved coat quality.",
    link: "https://amzn.to/3VZ9v5c"
  },
  {
    name: "Wellness Complete Health Small Breed Dry Dog Food",
    image_url: "https://m.media-amazon.com/images/I/71HG4M-BUaL._AC_UL320_.jpg",
    review_count: 12662,
    gemini_summary: "Wellness Complete Health Small Breed Dry Dog Food is highly recommended by veterinarians for its high-quality ingredients and suitability for pets with food sensitivities.",
    link: "https://amzn.to/3ZV4sUO"
  },
  {
    name: "ACANA Butcher's Favorites Dry Dog Food",
    image_url: "https://m.media-amazon.com/images/I/713xlKqu19L._AC_UL320_.jpg",
    review_count: 4708,
    gemini_summary: "Customers highly praise the ACANA dog food brand for its excellent nutritional value, premium ingredients, and delicious taste.",
    link: "https://amzn.to/4031VYS"
  },
  {
    name: "The Honest Kitchen Whole Food Clusters Small Breed",
    image_url: "https://m.media-amazon.com/images/I/81ktb3JFCVL._AC_UL320_.jpg",
    review_count: 871,
    gemini_summary: "The product is highly regarded for its suitability for picky eaters, sensitive stomachs, and fussy dogs.",
    link: "https://amzn.to/3PeJqvd"
  },
  {
    name: "ACANA Singles Limited Ingredient Dry Dog Food",
    image_url: "https://m.media-amazon.com/images/I/71bbWtdm4fL._AC_UL320_.jpg",
    review_count: 2503,
    gemini_summary: "Acana dog food is highly praised for its limited ingredients, health benefits, and palatability.",
    link: "https://amzn.to/3Pi21a2"
  },
  {
    name: "Instinct Raw Boost Grain Free Recipe",
    image_url: "https://m.media-amazon.com/images/I/91o0uLDZkLL._AC_UL320_.jpg",
    review_count: 3532,
    gemini_summary: "The product is highly rated by customers, especially those with dogs with sensitive stomachs or picky eating habits.",
    link: "https://amzn.to/4iOHBmx"
  },
  {
    name: "Blue Buffalo Life Protection Formula Senior",
    image_url: "https://m.media-amazon.com/images/I/71VW7X8VufL._AC_UL320_.jpg",
    review_count: 9370,
    gemini_summary: "Multiple customers highly recommend this dog food. It is palatable even for picky eaters and small dogs.",
    link: "https://amzn.to/4gV0BxL"
  },
  {
    name: "Hill's Science Diet Dry Dog Food, Adult, Small Bites",
    image_url: "https://m.media-amazon.com/images/I/71Nnzsf4CTL._AC_UL480_QL65_.jpg",
    review_count: 15234,
    gemini_summary: "Hill's Science Diet is trusted by veterinarians and provides balanced nutrition for adult dogs.",
    link: "https://amzn.to/3ZUYdjN"
  },
  {
    name: "Purina Pro Plan High Protein Dog Food",
    image_url: "https://m.media-amazon.com/images/I/81aO-4mO16L._AC_UL480_QL65_.jpg",
    review_count: 8976,
    gemini_summary: "High-protein formula with real meat as the first ingredient, supporting muscle maintenance and active lifestyles.",
    link: "https://amzn.to/3VX3Exv"
  },
  {
    name: "Royal Canin Size Health Nutrition Medium Adult",
    image_url: "https://m.media-amazon.com/images/I/71KllnHcDHL._AC_UL480_QL65_.jpg",
    review_count: 7123,
    gemini_summary: "Specifically formulated for medium-sized adult dogs, with balanced nutrition and optimal kibble size.",
    link: "https://amzn.to/3VX3Exv"
  },
  {
    name: "Merrick Grain Free Dry Dog Food",
    image_url: "https://m.media-amazon.com/images/I/815QHqmX+PL._AC_UL480_QL65_.jpg",
    review_count: 5421,
    gemini_summary: "Premium grain-free recipe with deboned meat as first ingredient and glucosamine for joint health.",
    link: "https://amzn.to/3PdQlVA"
  },
  {
    name: "Nutro Natural Choice Large Breed Adult",
    image_url: "https://m.media-amazon.com/images/I/81ll3cDommL._AC_UL480_QL65_.jpg",
    review_count: 4532,
    gemini_summary: "Made with non-GMO ingredients and tailored for large breed adult dogs' specific nutritional needs.",
    link: "https://amzn.to/3BF28t4"
  },
  {
    name: "Taste of the Wild High Prairie Grain-Free",
    image_url: "https://m.media-amazon.com/images/I/81O3pY6g4bL._AC_UL480_QL65_.jpg",
    review_count: 12543,
    gemini_summary: "Features real roasted meats and a unique blend of probiotics for digestive health.",
    link: "https://amzn.to/3Dxvk5H"
  },
  {
    name: "Iams ProActive Health Adult MiniChunks",
    image_url: "https://m.media-amazon.com/images/I/7102Zfb6L+L._AC_UL480_QL65_.jpg",
    review_count: 9876,
    gemini_summary: "Veterinarian-recommended formula with real chicken and essential nutrients for healthy adult dogs.",
    link: "https://amzn.to/3VVqxBm"
  },
  {
    name: "Wellness CORE Natural Grain Free",
    image_url: "https://m.media-amazon.com/images/I/711Z4RNBHOL._AC_UL480_QL65_.jpg",
    review_count: 6789,
    gemini_summary: "Premium protein-focused nutrition with added probiotics and omega fatty acids.",
    link: "https://amzn.to/41SNvgc"
  },
  {
    name: "Diamond Naturals Real Meat Recipe",
    image_url: "https://m.media-amazon.com/images/I/61xa91uVKUL._AC_UL480_QL65_.jpg",
    review_count: 8234,
    gemini_summary: "Made with real pasture-raised lamb and superfoods for complete nutrition.",
    link: "https://amzn.to/3PgYDvZ"
  },
  {
    name: "Fromm Family Foods Gold Adult Dog Food",
    image_url: "https://m.media-amazon.com/images/I/71iFvC08uXL._AC_UL480_QL65_.jpg",
    review_count: 3421,
    gemini_summary: "Family-owned company producing high-quality food with select proteins and wholesome ingredients.",
    link: "https://amzn.to/4iSeYor"
  },
  {
    name: "Nature's Recipe Grain Free Easy to Digest",
    image_url: "https://m.media-amazon.com/images/I/71s3EA0LKvL._AC_UL480_QL65_.jpg",
    review_count: 5678,
    gemini_summary: "Easily digestible formula with sweet potato and pumpkin for sensitive stomachs.",
    link: "https://amzn.to/3DBjDuV"
  },
  {
    name: "Rachael Ray Nutrish Premium Natural",
    image_url: "https://m.media-amazon.com/images/I/81LvtwKcAgL._AC_UL480_QL65_.jpg",
    review_count: 7890,
    gemini_summary: "Real meat first with added vitamins and minerals, no artificial preservatives.",
    link: "https://amzn.to/3VXP1dp"
  },
  {
    name: "Whole Earth Farms Grain Free Recipe",
    image_url: "https://m.media-amazon.com/images/I/71wRMDmBmdL._AC_UL480_QL65_.jpg",
    review_count: 4567,
    gemini_summary: "Holistic recipe made in the USA with high-quality proteins and produce.",
    link: "https://amzn.to/4fBZWAs"
  },
  {
    name: "Victor Purpose Nutra Pro Dog Food",
    image_url: "https://m.media-amazon.com/images/I/61mueG6q0gL._AC_UL480_QL65_.jpg",
    review_count: 3456,
    gemini_summary: "High-protein formula ideal for sporting dogs and puppies with active lifestyles.",
    link: "https://amzn.to/4fxpH4M"
  },
  {
    name: "Earthborn Holistic Great Plains Feast",
    image_url: "https://m.media-amazon.com/images/I/51HVOQ2ZoGL._AC_UL480_QL65_.jpg",
    review_count: 2345,
    gemini_summary: "Grain-free formula with lean, red meat protein and antioxidant-rich ingredients.",
    link: "https://amzn.to/3DBt8tV"
  },
  {
    name: "Canidae PURE Limited Ingredient",
    image_url: "https://m.media-amazon.com/images/I/714xVelokjL._AC_UL480_QL65_.jpg",
    review_count: 5432,
    gemini_summary: "Simple recipes made with 7-10 key ingredients for sensitive dogs.",
    link: "https://amzn.to/4gS2ueG"
  },
  {
    name: "Natural Balance Limited Ingredient",
    image_url: "https://m.media-amazon.com/images/I/71wRlnJ0uQL._AC_UL480_QL65_.jpg",
    review_count: 6543,
    gemini_summary: "Limited ingredient formula helps minimize the number of ingredients dogs are exposed to.",
    link: "https://amzn.to/4j77IVY"
  },
  {
    name: "Solid Gold - Wolf King with Real Natural Bison",
    image_url: "https://m.media-amazon.com/images/I/71zGklcOebL._AC_UL480_QL65_.jpg",
    review_count: 3234,
    gemini_summary: "Holistic adult dog food with lean bison protein and superfoods.",
    link: "https://amzn.to/3VXP4WD"
  },
  {
    name: "Nulo Freestyle Limited Plus Grain Free",
    image_url: "https://m.media-amazon.com/images/I/71w5h9EbK-L._AC_UL480_QL65_.jpg",
    review_count: 2123,
    gemini_summary: "Single-source protein recipe with low-glycemic ingredients and probiotics.",
    link: "https://amzn.to/3VXnGYC"
  },
  {
    name: "Zignature Limited Ingredient Formula",
    image_url: "https://m.media-amazon.com/images/I/71SVfAe9wjL._AC_UL480_QL65_.jpg",
    review_count: 4321,
    gemini_summary: "Limited ingredient diet with novel proteins for food sensitivities.",
    link: "https://amzn.to/4iTQB9Y"
  }
];