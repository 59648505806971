import React from 'react';
import { Leaf } from 'lucide-react';

export function AlternativeOptions() {
  const alternatives = [
    {
      type: "Limited Ingredient Diets",
      description: "Contains fewer ingredients while maintaining grains",
      bestFor: "Dogs with specific food sensitivities",
      features: [
        "Simple ingredient list",
        "Easy to identify allergens",
        "Often includes traditional grains"
      ]
    },
    {
      type: "Ancient Grain Formulas",
      description: "Uses alternative grains like quinoa and millet",
      bestFor: "Dogs needing grain variety",
      features: [
        "Novel grain sources",
        "Rich in nutrients",
        "Often more digestible"
      ]
    },
    {
      type: "Balanced Traditional Diets",
      description: "High-quality foods with conventional grains",
      bestFor: "Most healthy adult dogs",
      features: [
        "Proven nutrition profile",
        "Cost-effective",
        "Well-researched ingredients"
      ]
    }
  ];

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Alternative Options</h2>
      
      <div className="grid gap-6 md:grid-cols-3">
        {alternatives.map((alt) => (
          <div key={alt.type} className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="p-6">
              <div className="flex items-center mb-4">
                <Leaf className="h-6 w-6 text-emerald-600 mr-3" />
                <h3 className="text-xl font-semibold text-gray-900">{alt.type}</h3>
              </div>
              <p className="text-gray-600 mb-4">{alt.description}</p>
              <div className="mb-4">
                <span className="text-sm font-medium text-emerald-600">Best for: </span>
                <span className="text-gray-700">{alt.bestFor}</span>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-900 mb-2">Key Features:</h4>
                <ul className="space-y-1">
                  {alt.features.map((feature) => (
                    <li key={feature} className="flex items-center text-gray-600">
                      <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}