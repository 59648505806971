import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigation } from '../../contexts/NavigationContext';

export function BackToBlog() {
  const { navigate } = useNavigation();

  return (
    <button
      onClick={() => navigate('blog')}
      className="mb-8 flex items-center text-emerald-600 hover:text-emerald-700 transition-colors duration-200"
    >
      <ArrowLeft className="h-5 w-5 mr-2" />
      <span className="font-medium">Back to Blog</span>
    </button>
  );
}