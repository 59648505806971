import React from 'react';
import { Award } from 'lucide-react';

export function BrandsHero() {
  return (
    <div className="relative bg-emerald-50 py-16 sm:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
              Top Dog Food Brands
            </h1>
            <p className="mt-6 text-xl text-gray-500">
              Discover and compare leading dog food brands, evaluated for quality, nutrition, and value.
            </p>
            <div className="mt-8 flex items-center space-x-4">
              <Award className="h-6 w-6 text-emerald-600" />
              <span className="text-lg font-medium text-gray-900">Expert-reviewed brand analysis</span>
            </div>
          </div>
          <div className="mt-12 lg:mt-0">
            <img
              className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              src="https://images.unsplash.com/photo-1601758125946-6ec2ef64daf8?auto=format&fit=crop&q=80&w=1024"
              alt="Various dog food brands"
            />
          </div>
        </div>
      </div>
    </div>
  );
}