import React from 'react';
import { AlertCircle } from 'lucide-react';

export function IngredientWatch() {
  const ingredients = [
    {
      name: 'Artificial Preservatives',
      examples: 'BHA, BHT, Ethoxyquin',
      concerns: 'Potential carcinogens, allergic reactions',
      alternatives: 'Natural preservatives like Vitamin C, E'
    },
    {
      name: 'Food Dyes',
      examples: 'Red 40, Blue 2, Yellow 5 & 6',
      concerns: 'Hyperactivity, allergic reactions',
      alternatives: 'Natural food coloring from vegetables'
    },
    {
      name: 'Meat By-products',
      examples: 'Unnamed meat meals, by-product meals',
      concerns: 'Variable quality, potential allergens',
      alternatives: 'Named meat sources, single protein'
    },
    {
      name: 'Common Fillers',
      examples: 'Corn gluten, wheat middlings',
      concerns: 'Low nutritional value, digestive issues',
      alternatives: 'Whole grains, vegetables'
    }
  ];

  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Ingredient Watch List</h2>
          <p className="mt-4 text-lg text-gray-600">Common ingredients to watch out for in dog food</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {ingredients.map((ingredient) => (
            <div key={ingredient.name} className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
              <div className="flex items-center mb-4">
                <AlertCircle className="h-6 w-6 text-amber-500 mr-2" />
                <h3 className="text-xl font-semibold text-gray-900">{ingredient.name}</h3>
              </div>
              <div className="space-y-4">
                <div>
                  <p className="text-sm font-medium text-gray-500">Examples</p>
                  <p className="mt-1 text-gray-700">{ingredient.examples}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">Health Concerns</p>
                  <p className="mt-1 text-gray-700">{ingredient.concerns}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500">Better Alternatives</p>
                  <p className="mt-1 text-gray-700">{ingredient.alternatives}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}