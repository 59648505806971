import React from 'react';
import { PieChart } from 'lucide-react';

export function NutritionalAnalysis() {
  const nutrients = [
    {
      name: "Crude Protein",
      description: "Minimum percentage of protein content, essential for muscle maintenance and growth.",
      typical: "18-32%",
      note: "Higher percentages typically found in puppy and performance formulas"
    },
    {
      name: "Crude Fat",
      description: "Minimum percentage of fat content, provides energy and supports nutrient absorption.",
      typical: "8-22%",
      note: "Varies based on formula type and intended use"
    },
    {
      name: "Crude Fiber",
      description: "Maximum percentage of fiber content, aids in digestion and gut health.",
      typical: "2-5%",
      note: "Too much can reduce nutrient absorption"
    },
    {
      name: "Moisture",
      description: "Maximum percentage of water content in the food.",
      typical: "10-12% for dry food, 75-85% for wet food",
      note: "Affects caloric density and shelf life"
    }
  ];

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Guaranteed Analysis</h2>
      
      <div className="space-y-6">
        {nutrients.map((nutrient) => (
          <div key={nutrient.name} className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              <PieChart className="h-6 w-6 text-emerald-600 mr-3" />
              <h3 className="text-xl font-semibold text-gray-900">{nutrient.name}</h3>
            </div>
            <p className="text-gray-600 mb-4">{nutrient.description}</p>
            <div className="bg-emerald-50 p-4 rounded-md">
              <div className="flex flex-col space-y-2">
                <div>
                  <span className="font-medium text-emerald-800">Typical Range: </span>
                  <span className="text-emerald-700">{nutrient.typical}</span>
                </div>
                <div>
                  <span className="font-medium text-emerald-800">Note: </span>
                  <span className="text-emerald-700">{nutrient.note}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}