import React from 'react';
import { Calendar, Clock, User } from 'lucide-react';

export function BlogHeader() {
  return (
    <header className="mb-12">
      <h1 className="text-4xl font-extrabold text-gray-900 mb-4">
        Understanding Dog Food Labels: A Complete Guide
      </h1>
      
      <div className="flex items-center space-x-6 text-gray-600 mb-8">
        <div className="flex items-center">
          <Calendar className="h-5 w-5 mr-2" />
          <span>March 15, 2024</span>
        </div>
        <div className="flex items-center">
          <Clock className="h-5 w-5 mr-2" />
          <span>8 min read</span>
        </div>
        <div className="flex items-center">
          <User className="h-5 w-5 mr-2" />
          <span>Dr. Sarah Johnson, DVM</span>
        </div>
      </div>

      <img
        src="https://images.unsplash.com/photo-1568640347023-a616a30bc3bd?auto=format&fit=crop&q=80&w=1600"
        alt="Dog food label being examined"
        className="w-full h-[400px] object-cover rounded-xl mb-8"
      />

      <p className="text-xl text-gray-600 leading-relaxed">
        Understanding dog food labels is crucial for making informed decisions about your pet's nutrition. 
        This comprehensive guide will help you decode the information on pet food packaging and make the 
        best choices for your furry friend.
      </p>
    </header>
  );
}