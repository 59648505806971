import { SpecialNeed } from '../types/special-needs';

export const specialNeeds: SpecialNeed[] = [
  {
    id: 'heart-health',
    title: 'Heart Health',
    description: 'Dietary management for cardiac conditions',
    recommendations: ['Low sodium options', 'Omega-3 fatty acids', 'L-carnitine supplementation'],
    tags: ['heart disease', 'cardiac', 'low sodium', 'taurine']
  },
  {
    id: 'allergies',
    title: 'Food Allergies',
    description: 'Solutions for food sensitivities and allergies',
    recommendations: ['Limited ingredient diets', 'Novel protein sources', 'Grain-free options'],
    tags: ['allergies', 'sensitivities', 'limited ingredient', 'hypoallergenic']
  },
  {
    id: 'digestive',
    title: 'Digestive Issues',
    description: 'Support for sensitive stomachs and GI health',
    recommendations: ['Easily digestible proteins', 'Fiber-rich ingredients', 'Probiotics'],
    tags: ['digestive health', 'sensitive stomach', 'probiotics', 'fiber']
  },
  {
    id: 'weight-management',
    title: 'Weight Management',
    description: 'Specialized diets for weight control',
    recommendations: ['Low-calorie options', 'High protein content', 'L-carnitine for metabolism'],
    tags: ['weight control', 'obesity', 'diet', 'metabolism']
  },
  {
    id: 'joint-health',
    title: 'Joint & Mobility',
    description: 'Support for arthritis and joint health',
    recommendations: ['Glucosamine', 'Chondroitin', 'Omega-3 fatty acids'],
    tags: ['arthritis', 'joint pain', 'mobility', 'senior care']
  },
  {
    id: 'kidney-disease',
    title: 'Kidney Disease',
    description: 'Management of renal conditions',
    recommendations: ['Low phosphorus', 'Controlled protein', 'B-vitamins'],
    tags: ['renal', 'kidney', 'phosphorus', 'protein restriction']
  },
  {
    id: 'dental-health',
    title: 'Dental Health',
    description: 'Support for oral hygiene',
    recommendations: ['Texture for cleaning', 'Antibacterial ingredients', 'Tartar control'],
    tags: ['dental', 'teeth', 'oral health', 'breath']
  },
  {
    id: 'skin-coat',
    title: 'Skin & Coat',
    description: 'Solutions for dermatological issues',
    recommendations: ['Omega fatty acids', 'Zinc', 'Vitamin E'],
    tags: ['skin', 'coat', 'dermatology', 'allergies']
  },
  {
    id: 'liver-support',
    title: 'Liver Support',
    description: 'Dietary management for liver conditions',
    recommendations: ['Moderate protein', 'Copper restricted', 'Antioxidants'],
    tags: ['liver', 'hepatic', 'detox', 'metabolism']
  },
  {
    id: 'diabetes',
    title: 'Diabetes Management',
    description: 'Support for blood sugar control',
    recommendations: ['Low glycemic ingredients', 'Fiber rich', 'Controlled carbohydrates'],
    tags: ['diabetes', 'blood sugar', 'insulin', 'weight']
  },
  {
    id: 'cancer-support',
    title: 'Cancer Support',
    description: 'Nutritional support during treatment',
    recommendations: ['High quality protein', 'Omega-3 fatty acids', 'Antioxidants'],
    tags: ['cancer', 'immune support', 'nutrition', 'recovery']
  },
  {
    id: 'pancreatitis',
    title: 'Pancreatitis',
    description: 'Management of pancreatic conditions',
    recommendations: ['Low fat', 'Easily digestible', 'Controlled protein'],
    tags: ['pancreas', 'digestion', 'low fat', 'sensitive']
  },
  {
    id: 'urinary-health',
    title: 'Urinary Health',
    description: 'Support for urinary tract conditions',
    recommendations: ['Controlled minerals', 'pH balanced', 'Added water content'],
    tags: ['urinary', 'bladder', 'crystals', 'pH']
  },
  {
    id: 'ibd',
    title: 'IBD/IBS',
    description: 'Management of inflammatory bowel conditions',
    recommendations: ['Hypoallergenic', 'Novel proteins', 'Prebiotics'],
    tags: ['IBD', 'IBS', 'gut health', 'inflammation']
  },
  {
    id: 'thyroid',
    title: 'Thyroid Support',
    description: 'Dietary management for thyroid conditions',
    recommendations: ['Iodine balanced', 'L-carnitine', 'Controlled calories'],
    tags: ['thyroid', 'metabolism', 'hormone', 'energy']
  }
];