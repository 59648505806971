import React from 'react';

interface ArticleWithAdsProps {
  children: React.ReactNode;
}

/**
 * ArticleWithAds component
 * 
 * This component provides a layout for article content.
 * Ad placement is now handled automatically by Google AdSense Auto Ads.
 */
export function ArticleWithAds({ children }: ArticleWithAdsProps) {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
        {/* Main content */}
        <div className="lg:col-span-8">
          {/* Article content */}
          <article className="prose prose-emerald max-w-none">
            {children}
          </article>
        </div>
        
        {/* Sidebar */}
        <div className="lg:col-span-4">
          <div className="sticky top-8">
            {/* Sidebar content can be added here */}
            <div className="bg-gray-50 p-6 rounded-lg shadow-sm">
              <h3 className="text-lg font-medium text-gray-900 mb-4">About PawfectChow</h3>
              <p className="text-gray-600">
                PawfectChow helps dog owners make informed decisions about their pets' nutrition 
                through research-backed recommendations and comprehensive reviews.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
