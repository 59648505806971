import React from 'react';

export function ExpertTips() {
  return (
    <div className="py-16 bg-emerald-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <img
              className="rounded-lg shadow-xl"
              src="https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?auto=format&fit=crop&q=80&w=1024"
              alt="Veterinarian examining dog"
            />
          </div>
          <div className="mt-12 lg:mt-0">
            <h2 className="text-3xl font-bold text-gray-900">Expert Veterinary Tips</h2>
            <div className="mt-6 space-y-6 text-gray-600">
              <p>
                "Monitoring your dog's weight and activity level is crucial when transitioning between life stages. Each dog ages differently, so while age is a good guideline, you should also consider their individual health status and energy levels."
              </p>
              <p>
                "Regular vet check-ups can help determine the right time to transition to a different life-stage food. Watch for signs like changes in activity level, weight, or appetite."
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}