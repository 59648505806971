import { LifeStage } from '../types/life-stages';

export const lifeStages: LifeStage[] = [
  {
    id: 'puppy',
    title: 'Puppy Nutrition',
    description: 'High-energy nutrition for growth and development',
    keyPoints: ['Higher protein and fat', 'DHA for brain development', 'Calcium for bones'],
    tags: ['puppy', 'growth', 'development', 'DHA']
  },
  {
    id: 'adult',
    title: 'Adult Maintenance',
    description: 'Balanced nutrition for maintenance and activity',
    keyPoints: ['Balanced protein and fat', 'Joint support', 'Weight management'],
    tags: ['adult', 'maintenance', 'balanced nutrition']
  },
  {
    id: 'senior',
    title: 'Senior Care',
    description: 'Specialized nutrition for aging support',
    keyPoints: ['Joint support', 'Cognitive health', 'Easy digestion'],
    tags: ['senior', 'aging', 'joint health', 'cognitive']
  }
];