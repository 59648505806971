import React from 'react';
import { X } from 'lucide-react';
import { useNavigation } from '../contexts/NavigationContext';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export function MobileMenu({ isOpen, onClose }: MobileMenuProps) {
  const { navigate } = useNavigation();

  const handleNavigation = (page: string) => {
    navigate(page);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 lg:hidden">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-75" onClick={onClose} />
      
      <div className="fixed inset-y-0 right-0 max-w-xs w-full bg-white shadow-xl flex flex-col">
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <span className="text-lg font-semibold text-gray-900">Menu</span>
          <button
            onClick={onClose}
            className="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <nav className="flex-1 px-4 py-6 space-y-2 overflow-y-auto">
          <button
            onClick={() => handleNavigation('food-types')}
            className="w-full px-4 py-3 text-left text-gray-700 hover:bg-emerald-50 hover:text-emerald-600 rounded-lg transition-colors duration-200"
          >
            Food Types
          </button>
          <button
            onClick={() => handleNavigation('life-stages')}
            className="w-full px-4 py-3 text-left text-gray-700 hover:bg-emerald-50 hover:text-emerald-600 rounded-lg transition-colors duration-200"
          >
            Life Stages
          </button>
          <button
            onClick={() => handleNavigation('special-needs')}
            className="w-full px-4 py-3 text-left text-gray-700 hover:bg-emerald-50 hover:text-emerald-600 rounded-lg transition-colors duration-200"
          >
            Special Needs
          </button>
          <button
            onClick={() => handleNavigation('brands')}
            className="w-full px-4 py-3 text-left text-gray-700 hover:bg-emerald-50 hover:text-emerald-600 rounded-lg transition-colors duration-200"
          >
            Top Brands
          </button>
          <button
            onClick={() => handleNavigation('blog')}
            className="w-full px-4 py-3 text-left text-gray-700 hover:bg-emerald-50 hover:text-emerald-600 rounded-lg transition-colors duration-200"
          >
            Blog
          </button>
        </nav>
      </div>
    </div>
  );
}