import React from 'react';
import { FileText, AlertCircle } from 'lucide-react';

export function ResearchFindings() {
  const studies = [
    {
      title: "FDA Investigation (2018-2020)",
      findings: "Identified potential link between grain-free diets and DCM in dogs",
      impact: "Led to increased scrutiny of grain-free formulations"
    },
    {
      title: "Tufts University Study",
      findings: "Examined nutritional profiles of grain-free vs. grain-inclusive diets",
      impact: "Found varying nutrient levels and quality across brands"
    },
    {
      title: "Ongoing Research",
      findings: "Investigating role of legumes and exotic ingredients",
      impact: "Continues to shape understanding of diet-related health issues"
    }
  ];

  return (
    <section className="py-12 border-t border-gray-200">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Latest Research Findings</h2>
      
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <div className="flex items-center mb-6">
          <FileText className="h-6 w-6 text-emerald-600 mr-3" />
          <h3 className="text-xl font-semibold text-gray-900">Key Studies</h3>
        </div>
        
        <div className="space-y-6">
          {studies.map((study) => (
            <div key={study.title} className="border-l-4 border-emerald-500 pl-4">
              <h4 className="font-medium text-gray-900 mb-2">{study.title}</h4>
              <p className="text-gray-600 mb-2">
                <span className="font-medium">Findings: </span>
                {study.findings}
              </p>
              <p className="text-gray-600">
                <span className="font-medium">Impact: </span>
                {study.impact}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-amber-50 rounded-lg p-6">
        <div className="flex items-center mb-4">
          <AlertCircle className="h-6 w-6 text-amber-600 mr-3" />
          <h3 className="text-lg font-semibold text-gray-900">Important Note</h3>
        </div>
        <p className="text-gray-700">
          Research in this area is ongoing, and findings continue to evolve. Always consult with your 
          veterinarian before making significant changes to your dog's diet, especially if they have 
          existing health conditions or concerns.
        </p>
      </div>
    </section>
  );
}