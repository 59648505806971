import React from 'react';
import { useCarousel } from '../../hooks/useCarousel';
import { CarouselControls } from './CarouselControls';
import { BrandCard } from './BrandCard';
import { useWindowSize } from '../../hooks/useWindowSize';

interface Brand {
  name: string;
  rating: number;
  priceRange: string;
  specialty: string;
  image: string;
  features: string[];
  link?: string;
}

interface BrandCarouselProps {
  brands: Brand[];
}

export function BrandCarousel({ brands }: BrandCarouselProps) {
  const { width } = useWindowSize();
  
  // Determine number of visible brands based on screen width
  const getVisibleBrands = () => {
    if (width < 640) return 1; // Mobile
    if (width < 1024) return 2; // Tablet
    if (width < 1536) return 3; // Small desktop
    return 4; // Large desktop
  };
  
  const visibleBrands = getVisibleBrands();
  const totalSlides = Math.ceil(brands.length / visibleBrands);
  
  const {
    currentIndex,
    isPaused,
    setIsPaused,
    goToSlide,
    nextSlide,
    previousSlide
  } = useCarousel({ totalSlides });

  return (
    <div 
      className="relative w-full px-4 sm:px-6 lg:px-8"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <div className="relative overflow-hidden">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {Array.from({ length: totalSlides }).map((_, slideIndex) => (
            <div 
              key={slideIndex}
              className="flex flex-shrink-0 w-full gap-4 lg:gap-6"
            >
              {brands
                .slice(slideIndex * visibleBrands, (slideIndex + 1) * visibleBrands)
                .map((brand, index) => (
                  <div 
                    key={`${slideIndex}-${index}`}
                    className="w-full"
                    style={{ 
                      flexBasis: `${100 / visibleBrands}%`,
                      maxWidth: `${100 / visibleBrands}%`
                    }}
                  >
                    <BrandCard brand={brand} />
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>

      <CarouselControls
        totalSlides={totalSlides}
        currentIndex={currentIndex}
        onPrevious={previousSlide}
        onNext={nextSlide}
        onDotClick={goToSlide}
      />
    </div>
  );
}