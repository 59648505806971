import React from 'react';

export function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-extrabold text-gray-900 mb-8">Privacy Policy</h1>
        
        <div className="prose prose-emerald max-w-none">
          <p className="text-gray-600 mb-8">Last updated: {new Date().toLocaleDateString()}</p>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Information We Collect</h2>
            <p className="text-gray-600 mb-4">We collect information that you provide directly to us, including:</p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>Usage data and analytics through Google Analytics</li>
              <li>Search queries and interactions with our website</li>
              <li>Browser type and version</li>
              <li>Operating system</li>
              <li>Referral source</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">2. How We Use Your Information</h2>
            <p className="text-gray-600 mb-4">We use the information we collect to:</p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>Improve and optimize our website</li>
              <li>Analyze user behavior and preferences</li>
              <li>Provide personalized content recommendations</li>
              <li>Monitor and analyze trends and usage</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Cookies and Tracking Technologies</h2>
            <p className="text-gray-600 mb-4">
              We use cookies and similar tracking technologies to track activity on our website and hold certain information. 
              Cookies are files with small amount of data which may include an anonymous unique identifier.
            </p>
            <p className="text-gray-600 mb-4">
              We use the following types of cookies:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>Essential cookies for website functionality</li>
              <li>Analytics cookies (Google Analytics)</li>
              <li>Preference cookies</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Third-Party Services</h2>
            <p className="text-gray-600 mb-4">
              We use third-party services that may collect information used to identify you:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>Google Analytics for website analytics</li>
              <li>Amazon Associates Program for affiliate marketing</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Data Security</h2>
            <p className="text-gray-600 mb-4">
              The security of your data is important to us, but remember that no method of transmission over 
              the Internet, or method of electronic storage is 100% secure.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Changes to This Privacy Policy</h2>
            <p className="text-gray-600 mb-4">
              We may update our Privacy Policy from time to time. We will notify you of any changes by 
              posting the new Privacy Policy on this page and updating the "Last updated" date.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Contact Us</h2>
            <p className="text-gray-600">
              If you have any questions about this Privacy Policy, please contact us at:{' '}
              <a href="mailto:contact@pawfectchow.com" className="text-emerald-600 hover:text-emerald-500">
                contact@pawfectchow.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}