import React from 'react';
import { FoodTypeHero } from '../components/food-types/FoodTypeHero';
import { ComparisonTable } from '../components/food-types/ComparisonTable';
import { DietTypes } from '../components/food-types/DietTypes';
import { NutritionGuide } from '../components/food-types/NutritionGuide';
import { ExpertAdvice } from '../components/food-types/ExpertAdvice';
import { CategoryLayout } from '../components/layouts/CategoryLayout';

export function FoodTypes() {
  return (
    <CategoryLayout>
      <FoodTypeHero />
      <DietTypes />
      <ComparisonTable />
      <NutritionGuide />
      <ExpertAdvice />
    </CategoryLayout>
  );
}