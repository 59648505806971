import React, { useState, useRef, useEffect } from 'react';
import { Search as SearchIcon, X } from 'lucide-react';
import { SearchResult } from '../../types/search';
import { searchContent } from '../../utils/search';
import { searchableContent } from '../../data/searchContent';
import { SearchResults } from './SearchResults';

export function SearchBar() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearch = (value: string) => {
    setQuery(value);
    if (value.trim().length >= 2) {
      const searchResults = searchContent(value, searchableContent);
      setResults(searchResults);
      setIsOpen(true);
    } else {
      setResults([]);
      setIsOpen(false);
    }
  };

  const clearSearch = () => {
    setQuery('');
    setResults([]);
    setIsOpen(false);
  };

  return (
    <div ref={searchRef} className="relative">
      <div className="relative">
        <input
          type="text"
          value={query}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search for dog food, nutrition info, or brands..."
          className="w-full pl-5 pr-12 py-3 border-2 border-gray-300 rounded-full focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-4">
          {query ? (
            <button onClick={clearSearch} className="text-gray-400 hover:text-gray-600">
              <X className="h-5 w-5" />
            </button>
          ) : (
            <SearchIcon className="h-6 w-6 text-emerald-600" strokeWidth={2.5} />
          )}
        </div>
      </div>

      {isOpen && results.length > 0 && (
        <SearchResults results={results} onClose={() => setIsOpen(false)} />
      )}
    </div>
  );
}