import { useState, useRef, useEffect } from 'react';

interface UseCarouselProps {
  totalSlides: number;
  autoScrollInterval?: number;
}

export function useCarousel({ totalSlides, autoScrollInterval = 5000 }: UseCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const autoScrollTimeoutRef = useRef<NodeJS.Timeout>();

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev === totalSlides - 1 ? 0 : prev + 1));
  };

  const previousSlide = () => {
    setCurrentIndex((prev) => (prev === 0 ? totalSlides - 1 : prev - 1));
  };

  useEffect(() => {
    if (autoScrollTimeoutRef.current) {
      clearTimeout(autoScrollTimeoutRef.current);
    }

    if (!isPaused) {
      autoScrollTimeoutRef.current = setTimeout(() => {
        nextSlide();
      }, autoScrollInterval);
    }

    return () => {
      if (autoScrollTimeoutRef.current) {
        clearTimeout(autoScrollTimeoutRef.current);
      }
    };
  }, [currentIndex, isPaused, autoScrollInterval, totalSlides]);

  return {
    currentIndex,
    isPaused,
    setIsPaused,
    goToSlide,
    nextSlide,
    previousSlide
  };
}