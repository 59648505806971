import React from 'react';
import { Apple, Baby, Heart, Award } from 'lucide-react';
import { useNavigation } from '../contexts/NavigationContext';

export function FeaturedCategories() {
  const { navigate } = useNavigation();
  
  const categories = [
    {
      icon: <Apple className="h-8 w-8" />,
      title: 'Food Types',
      description: 'Compare kibble, wet food, raw diets and more',
      stats: '6 food types analyzed',
      page: 'food-types'
    },
    {
      icon: <Baby className="h-8 w-8" />,
      title: 'Life Stages',
      description: 'From puppy to senior, find age-appropriate nutrition',
      stats: 'Guides for every age',
      page: 'life-stages'
    },
    {
      icon: <Heart className="h-8 w-8" />,
      title: 'Special Needs',
      description: 'Solutions for allergies and health conditions',
      stats: '15+ conditions covered',
      page: 'special-needs'
    },
    {
      icon: <Award className="h-8 w-8" />,
      title: 'Top Brands',
      description: 'Discover and compare leading dog food brands',
      stats: '40+ brands reviewed',
      page: 'brands'
    },
  ];

  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Explore Dog Food Categories</h2>
          <p className="mt-4 text-lg text-gray-600">Find the perfect nutrition solution for your furry friend</p>
        </div>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
          {categories.map((category) => (
            <div
              key={category.title}
              onClick={() => navigate(category.page)}
              className="group relative bg-white rounded-xl overflow-hidden transition-all duration-300 hover:shadow-xl border border-gray-100 cursor-pointer"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-emerald-500/5 to-emerald-500/10 opacity-0 group-hover:opacity-100 transition-opacity" />
              
              <div className="relative p-8">
                <div className="inline-flex p-3 rounded-xl bg-emerald-50 text-emerald-600 group-hover:scale-110 group-hover:bg-emerald-100 transition-all duration-300">
                  {category.icon}
                </div>

                <div className="mt-6">
                  <h3 className="text-xl font-semibold text-gray-900 group-hover:text-emerald-600 transition-colors">
                    {category.title}
                  </h3>
                  <p className="mt-2 text-gray-600">
                    {category.description}
                  </p>
                  <div className="mt-4 text-sm font-medium text-emerald-600">
                    {category.stats}
                  </div>
                </div>

                <div className="mt-6 flex items-center text-emerald-600 font-medium">
                  Learn more
                  <svg className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}