import React from 'react';
import { CheckCircle, Shield, Microscope, Award } from 'lucide-react';

export function QualityStandards() {
  const standards = [
    {
      icon: <CheckCircle className="h-8 w-8" />,
      title: 'Ingredient Quality',
      description: 'Premium, human-grade ingredients with no artificial preservatives or fillers.'
    },
    {
      icon: <Shield className="h-8 w-8" />,
      title: 'Safety Standards',
      description: 'Rigorous testing and quality control measures throughout production.'
    },
    {
      icon: <Microscope className="h-8 w-8" />,
      title: 'Nutritional Research',
      description: 'Backed by scientific studies and veterinary nutritionists.'
    },
    {
      icon: <Award className="h-8 w-8" />,
      title: 'Manufacturing Excellence',
      description: 'Produced in certified facilities with strict quality controls.'
    }
  ];

  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900">Our Quality Standards</h2>
          <p className="mt-4 text-xl text-gray-600">What we look for in top dog food brands</p>
        </div>

        <div className="mt-12 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {standards.map((standard) => (
            <div key={standard.title} className="bg-emerald-50 rounded-xl p-8 text-center hover:shadow-lg transition-shadow duration-300">
              <div className="inline-flex p-3 rounded-full bg-emerald-100 text-emerald-600 mb-4">
                {standard.icon}
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{standard.title}</h3>
              <p className="text-gray-600">{standard.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}