// Performance monitoring and optimization utilities
export function initializePerformanceMonitoring() {
  // Monitor Core Web Vitals
  if ('web-vital' in window) {
    // @ts-ignore
    webVitals.getCLS(console.log);
    // @ts-ignore
    webVitals.getFID(console.log);
    // @ts-ignore
    webVitals.getLCP(console.log);
  }

  // Initialize Intersection Observer for lazy loading
  initializeLazyLoading();
}

function initializeLazyLoading() {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const element = entry.target;
          if (element instanceof HTMLImageElement) {
            element.src = element.dataset.src || '';
            observer.unobserve(element);
          }
        }
      });
    },
    {
      rootMargin: '50px 0px',
      threshold: 0.1
    }
  );

  // Observe all images with data-src attribute
  document.querySelectorAll('img[data-src]').forEach(img => {
    observer.observe(img);
  });
}

export function deferNonCriticalLoad() {
  // Defer non-critical CSS
  const nonCriticalCSS = document.querySelectorAll('link[data-priority="low"]');
  nonCriticalCSS.forEach(link => {
    link.setAttribute('media', 'print');
    link.setAttribute('onload', "this.media='all'");
  });

  // Defer non-critical JavaScript
  const nonCriticalScripts = document.querySelectorAll('script[data-priority="low"]');
  nonCriticalScripts.forEach(script => {
    script.setAttribute('defer', '');
  });
}