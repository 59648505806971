import React, { useState } from 'react';
import { Star, DollarSign, Shield, ExternalLink } from 'lucide-react';
import { event } from '../../utils/analytics';
import { FeatureTooltip } from './FeatureTooltip';

interface BrandCardProps {
  brand: {
    name: string;
    rating: number;
    priceRange: string;
    specialty: string;
    image: string;
    features: string[];
    link?: string;
  };
}

export function BrandCard({ brand }: BrandCardProps) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleBuyClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    
    if (brand.link) {
      event({
        action: 'affiliate_link_click',
        category: 'Affiliate',
        label: brand.name
      });

      window.open(brand.link, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="h-full">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 h-full flex flex-col">
        <div className="h-48 overflow-hidden">
          <img 
            className="w-full h-full object-contain bg-white p-2" 
            src={brand.image} 
            alt={brand.name} 
          />
        </div>
        <div className="p-4 flex-grow flex flex-col">
          <h3 className="text-lg font-semibold text-gray-900 line-clamp-2 mb-2">{brand.name}</h3>
          
          <div className="space-y-2 mb-4">
            <div className="flex items-center">
              <Star className="h-5 w-5 text-yellow-400" />
              <span className="ml-2 text-gray-600">{brand.rating} / 5.0</span>
            </div>
            <div className="flex items-center">
              <DollarSign className="h-5 w-5 text-emerald-600" />
              <span className="ml-2 text-gray-600">{brand.priceRange}</span>
            </div>
            <div className="flex items-center">
              <Shield className="h-5 w-5 text-purple-600" />
              <span className="ml-2 text-gray-600 line-clamp-1">{brand.specialty}</span>
            </div>
          </div>

          <div className="relative mt-auto">
            <div 
              className="relative cursor-help"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <h4 className="font-medium text-gray-900 mb-1">Key Features:</h4>
              <ul className="space-y-1 mb-4">
                {brand.features.map((feature) => (
                  <li key={feature} className="flex items-center text-gray-600">
                    <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2 flex-shrink-0"></span>
                    <span className="line-clamp-1">{feature}</span>
                  </li>
                ))}
              </ul>
              <FeatureTooltip features={brand.features} isVisible={showTooltip} />
            </div>
            
            {brand.link && (
              <button
                onClick={handleBuyClick}
                className="w-full flex items-center justify-center px-4 py-2 bg-amber-500 hover:bg-amber-600 text-white font-medium rounded-lg transition-colors duration-200"
              >
                <ExternalLink className="h-4 w-4 mr-2" />
                Buy on Amazon
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}