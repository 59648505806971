import React from 'react';
import { PageLayout } from './PageLayout';

interface ContentLayoutProps {
  children: React.ReactNode;
  sidebar?: React.ReactNode;
}

export function ContentLayout({ children, sidebar }: ContentLayoutProps) {
  return (
    <PageLayout maxWidth="wide">
      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
        <main className={`${sidebar ? 'lg:col-span-8' : 'lg:col-span-12'}`}>
          {children}
        </main>
        
        {sidebar && (
          <aside className="lg:col-span-4">
            <div className="sticky top-8">
              {sidebar}
            </div>
          </aside>
        )}
      </div>
    </PageLayout>
  );
}