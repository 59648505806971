import React from 'react';
import { useNavigation } from '../../contexts/NavigationContext';
import { blogPosts } from '../../data/blogPosts';
import { MainBlogPost } from './MainBlogPost';
import { Calendar } from 'lucide-react';

export function BlogList() {
  const { navigate } = useNavigation();

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      {/* Main Article */}
      <MainBlogPost />

      {/* More Articles */}
      <h2 className="text-2xl font-bold text-gray-900 mb-8">More Articles</h2>
      <div className="grid gap-8 md:grid-cols-3">
        {blogPosts.slice(1).map((post) => (
          <article 
            key={post.id}
            onClick={() => navigate(post.page)}
            className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          >
            <img
              className="h-48 w-full object-cover"
              src={post.image}
              alt={post.title}
            />
            <div className="p-6">
              <div className="text-sm font-medium text-emerald-600 bg-emerald-50 inline-block px-3 py-1 rounded-full">
                {post.category}
              </div>
              <h3 className="mt-2 text-lg font-semibold text-gray-900 hover:text-emerald-600 transition-colors duration-200">
                {post.title}
              </h3>
              <p className="mt-2 text-sm text-gray-600 line-clamp-2">{post.excerpt}</p>
              <div className="mt-4 flex items-center text-sm text-gray-500">
                <Calendar className="h-4 w-4 mr-1" />
                <span>{post.date}</span>
              </div>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
}